import { User } from '../../models/User'
import { Intent } from '../../models/Intent'
import { generateBot } from '../../models/BotGenerator'
import BotMakerService from '../../services/botMaker.service'
import { PLATFORMS } from '@/constants/constants'
import {botTimeoutMessageValidationFactory} from '../factories'
import orderBy from 'lodash/orderBy'
import find from 'lodash/find'

const actions = {
  async GET_BOTS({ commit }) {
    try {
      let botsResponse = await BotMakerService.getBots()
      let bots = []
      for (let i = 0; i < botsResponse.data.length; i++) {
        let bot = botsResponse.data[i]
        bots.push(generateBot(bot))
      }

      const sortedBots = orderBy(bots, 'createdAt', 'desc')
      commit('SET_BOTS', sortedBots)
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  // Users
  LOAD_USERS: ({ commit }) => {
    return BotMakerService.getUsers()
      .then(response => {
        let users = response.data.map(u => {
          return new User(
            u.name,
            u.email,
            u.password,
            u.roles,
            u.company,
            u._id
          )
        })
        commit('SET_USERS', users)
        return users
      })
      .catch(error => {
        console.error(error)
        return error
      })
  },
  LOAD_AGENTS: ({ commit }, useStaffManagement) => {
    const path = useStaffManagement ? '/agents' : '?where[roles][$in]=5cd08f88e9d215006fff7716&where[roles][$in]=5cd08f88e9d215006fff7716&where[roles][$nin]=5cd9a8f9f99a38006e7b8b1c&where[roles][$nin]=5cd09eb2e9d215006fff774b'
    return BotMakerService.getUsers(path)
      .then(response => {
        let users = response.data.map(u => {
          return new User(
            u.name,
            u.email,
            u.password,
            u.roles,
            u.company,
            u._id
          )
        })
        commit('SET_USERS', users)
        return users
      })
      .catch(error => {
        console.error(error)
        return error
      })
  },
  LOAD_QUEUES: ({ commit }, withDefault) => {
    return BotMakerService.getQueues()
      .then(response => {
        if (withDefault){
          commit('SET_QUEUES', response.data)
        } else {
          commit('SET_QUEUES', response.data.filter(q => !q.isDefaultQueue))
        }
        return;
      })
      .catch(error => {
        console.error(error)
        return error
      })
  },

  // Bot
  DELETE_BOT({ dispatch }, botId) {
    return BotMakerService.deleteBot(botId)
      .then(() => {
        return dispatch('GET_BOTS')
      })
      .catch(error => {
        return error
      })
  },

  SAVE_TIMEOUT_VALIDATION({ state }, botId) {
    try {
      if (state.hasTimeoutValidation) {
        let optionToSave = state.botTimeoutMessageValidation
        if (optionToSave.negative.actions[0].key === 'stopTakeoverAndJump') {
          optionToSave.negative.actions[1] = {
            key: 'jump',
            args: [optionToSave.negative.actions[0].args[0]]
          }
          optionToSave.negative.actions[0].args = []
          optionToSave.negative.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.positive.actions[0].key === 'stopTakeoverAndJump') {
          optionToSave.positive.actions[1] = {
            key: 'jump',
            args: [optionToSave.positive.actions[0].args[0]]
          }
          optionToSave.positive.actions[0].args = []
          optionToSave.positive.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.negative.actions[0].key === 'stopTakeoverAndText') {
          optionToSave.negative.actions[1] = {
            key: 'responseText',
            args: [optionToSave.negative.actions[0].args[0]]
          }
          optionToSave.negative.actions[0].args = []
          optionToSave.negative.actions[0].key = 'stopTakeover'
        }
        if (optionToSave.positive.actions[0].key === 'stopTakeoverAndText') {
          optionToSave.positive.actions[1] = {
            key: 'responseText',
            args: [optionToSave.positive.actions[0].args[0]]
          }
          optionToSave.positive.actions[0].args = []
          optionToSave.positive.actions[0].key = 'stopTakeover'
        }
        optionToSave.trigger.threshold =
          optionToSave.trigger.threshold * 60 * 1000
        optionToSave.replyWhileWaitingForAgent = !!optionToSave.replyWhileWaitingForAgent;
        if (optionToSave._id) {
          let id = optionToSave._id
          delete optionToSave._id
          delete optionToSave.data
          delete optionToSave.company
          delete optionToSave.bot
          delete optionToSave.type
          delete optionToSave._createdAt
          delete optionToSave._updatedAt
          delete optionToSave.__v
          delete optionToSave.positive._id
          delete optionToSave.negative._id
          optionToSave.service = {
            name: optionToSave.service.name
          }
          return BotMakerService.updateOption(optionToSave, id)
        } else {
          optionToSave.bot = botId
          optionToSave.type = 'longStandingTakeover'
          return BotMakerService.createOption(optionToSave)
        }
      } else {
        if (state.botTimeoutMessageValidation._id) {
          return BotMakerService.deleteOption(
            state.botTimeoutMessageValidation._id
          )
            .then(() => {
              state.botTimeoutMessageValidation = botTimeoutMessageValidationFactory()
              state.hasTimeoutValidation = false
              return
            })
            .catch(error => {
              return error
            })
        }
      }
    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },

  async GET_TIMEOUT_VALIDATION({ state }, botId) {
    try {
      const response = await BotMakerService.getOption(
        botId,
        'longStandingTakeover'
      )
      let option = response.data
      if (option.length > 0) {
        let optionToSave = option[0]
        if (
          optionToSave.negative.actions.length > 1 &&
          optionToSave.negative.actions[1].key === 'jump'
        ) {
          optionToSave.negative.actions = [
            {
              name: option[0].negative.actions[0].name,
              key: 'stopTakeoverAndJump',
              args: [option[0].negative.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.positive.actions.length > 1 &&
          optionToSave.positive.actions[1].key === 'jump'
        ) {
          optionToSave.positive.actions = [
            {
              name: option[0].positive.actions[0].name,
              key: 'stopTakeoverAndJump',
              args: [option[0].positive.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.negative.actions.length > 1 &&
          optionToSave.negative.actions[1].key === 'responseText'
        ) {
          optionToSave.negative.actions = [
            {
              name: option[0].negative.actions[0].name,
              key: 'stopTakeoverAndText',
              args: [option[0].negative.actions[1].args[0]]
            }
          ]
        }
        if (
          optionToSave.positive.actions.length > 1 &&
          optionToSave.positive.actions[1].key === 'responseText'
        ) {
          optionToSave.positive.actions = [
            {
              name: option[0].positive.actions[0].name,
              key: 'stopTakeoverAndText',
              args: [option[0].positive.actions[1].args[0]]
            }
          ]
        }
        optionToSave.trigger.threshold = option[0].trigger.threshold / 60 / 1000
        state.botTimeoutMessageValidation = optionToSave
        state.hasTimeoutValidation = true
      }
    } catch (error) {
      return await Promise.reject(error)
    }
  },

  // INTENTS
  async GET_VERSION_INTENTS({ state }, versionId) {
    try {
      // Get Dialogs for bot responses
      let dialogsResponse = await BotMakerService.getDialogs(versionId)
      let intentionsPerVersionResponse = await BotMakerService.getIntentionsPerVersion(
        versionId
      )
      let versionIntentions = intentionsPerVersionResponse.data
      let dialogs = dialogsResponse.data

      // TODO: Sonar: This assign seems to be bad
      return (state.bot.intents = versionIntentions.map(i => {
        let derivesToAgent =
          state.bot.rawIntents &&
          state.bot.rawIntents.includes(i.name.toLowerCase())

        let dialog = find(dialogs, d => {
          return d.condition.params.intent === i.name
        })

        const intent = state.bot?.intentsByPlatform?.find(
          ibp => ibp.intent === i.name
        )

        const intentsByPlatform = intent
          ? intent.platforms.map(ibp => PLATFORMS.find(p => p.value === ibp))
          : []

        return new Intent(
          i.name,
          i.examples,
          derivesToAgent,
          i._id,
          dialog ? dialog._id : undefined,
          intentsByPlatform
        )
      }))
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async SAVE_INTENTS({ state }, intents) {
    try {
      let intentions = intents ? intents : state.bot.intents
      let versionId = state.bot.activeVersion

      for (let i = 0; i < intentions.length; i++) {
        let intention = intentions[i]

        // If the intent was not saved, create a new one for the current version
        await BotMakerService.createIntent(
          versionId,
          intention.name,
          intention.examples
        )

        if (intention.derivesToAgent) {
          const dialog = state.bot.generateDialogToSave(intention.name)
          dialog.execTakeover = true
          await BotMakerService.createDialog(dialog)
        }
      }
    } catch (e) {
        console.error(e)
        return await Promise.reject(e)
    }
  },

  async UPDATE_INTENT(context, intent) {
    try {
      await BotMakerService.updateIntent(intent.id, {
        name: intent.name,
        examples: intent.examples
      })

    } catch (error) {
      // TODO: Refactor axios error response https://github.com/axios/axios/issues/960#issuecomment-320659373
      throw error.response.data[0]
    }
  },
}

export default actions
