export default {
  title: {
    es: 'Temas',
    en: 'Themes',
    pt: 'Temas'
  },
  form: {
    common: {
      default: {
        es: 'Por defecto',
        en: 'Default',
        pt: 'Predefinido'
      },
      reset: {
        es: 'Deshacer',
        en: 'Reset',
        pt: 'Restaurar'
      },
      save: {
        es: 'Guardar',
        en: 'Save',
        pt: 'Salvar'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    },
    colors: {
      title: {
        es: 'Colores',
        en: 'Colors',
        pt: 'Cores'
      },
      primary: {
        es: 'Elementos de los formularios.',
        en: 'Form elements.',
        pt: 'Elementos do formulário.'
      },
      success: {
        es: 'Mensajes de éxito.',
        en: 'Success messages.',
        pt: 'Mensagens de sucesso.'
      },
      danger: {
        es: 'Mensajes de error.',
        en: 'Error messages.',
        pt: 'Mensagens de erro.'
      },
      warning: {
        es: 'Mensajes de atención.',
        en: 'Warning messages.',
        pt: 'Mensagens de aviso.'
      },
      dark: {
        es: 'Colores oscuros.',
        en: 'Dark colors.',
        pt: 'Cores escuras.'
      },
      'warning-dark': {
        es: 'Color oscuro de atención.',
        en: 'Warning dark color.',
        pt: 'Aviso de cor escura.'
      }
    },
    theme: {
      title: {
        es: 'Tema',
        en: 'Theme',
        pt: 'Tema'
      }
    },
    companyLogo: {
      title: {
        es: 'Logo de la empresa',
        en: 'Company logo',
        pt: 'Logotipo da empresa'
      },
      label: {
        es: 'URL de imagen',
        en: 'Image URL',
        pt: 'URL da imagem'
      },
      preview: {
        es: 'Previsualizar',
        en: 'Preview',
        pt: 'Visualização'
      },
      error: {
        es: 'URL no válida (solo se permiten URLs con https://)',
        en: 'Invalid URL (only https:// URLs are allowed)',
        pt: 'URL não válido (apenas se permitir URLs com https://)'
      }
    }
  },
  save: {
    success: {
      title: {
        es: 'Éxito!',
        en: 'Success!',
        pt: 'Sucesso!'
      },
      text: {
        es: 'Tema actualizado exitosamente.',
        en: 'Theme updated succesfully.',
        pt: 'Tema atualizado com sucesso.'
      }
    },
    error: {
      title: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro'
      },
      text: {
        es: 'Error actualizando tema. Por favor, intente nuevamente.',
        en: 'Error updating theme. Please, try again.',
        pt: 'Erro na atualização do tema. Por favor, tente novamente.'
      }
    }
  }
}
