export default {

  queryBuilder: {

    chartNotLoaded: {
      en: 'Chart not loaded',
      es: 'Gráfico no cargado',
      pt: 'Gráfico não carregado'
    },
    chartNotLoadedGranularity: {
      en: 'Chart not loaded needs two granularity',
      es: 'Gráfico no cargado, necesita dos granularidades',
      pt: 'Gráfico não carregado precisa de duas granularidades'
   },   
    noDataAvailable: {
      en: 'No results were found',
      es: 'No se han encontrado resultados',
      pt: 'Não foram encontrados resultados'
    },
    templates: {
      totalHits: {
        title: {
          es: 'Total',
          en: 'Total',
          pt: 'Totais'
        },
      }
    },
    filters: {
      moreData: {
        label: {
          en: 'More data',
          es: 'Más info',
          pt: 'Mais dados'
        },
        labelWorkspace: {
          en: 'Go to workspace',
          es: 'Ir al espacio de trabajo',
          pt: 'Ir para o espaço de trabalho'
        }
      },
      infoTitle: {
        label: {
          en: 'Edit info title',
          es: 'Editar título informativo',
          pt: 'Editar título da informação'
        },
        labelInput: {
          en: 'Edit info title',
          es: 'Editar título informativo',
          pt: 'Editar título da informação'
        },
        button: {
          en: 'Edit info title',
          es: 'Editar título informativo',
          pt: 'Editar título da informação'
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      renameDimensionMeasure: {
        label: {
          en: 'Edit name',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        labelInput: {
          en: 'Rename',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        button: {
          en: 'Edit names',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        add: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar',
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      granularityFormat: {
        label: {
          en: 'Granularity format',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        labelInput: {
          en: 'Granularity format',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        button: {
          en: 'Granularity format',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        placeholder: {
          en: 'Select granularity format',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        add: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar',
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      editSeries: {
        label: {
          en: 'Edit series',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        labelInput: {
          en: 'Rename',
          es: 'Editar nombre',
          pt: 'Editar cor'
        },
        button: {
          en: 'Edit series',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        add: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar',
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      editLink: {
        label: {
          en: 'Edit link',
          es: 'Editar enlace',
          pt: 'Editar link'
        },
        linkKind: {
          en: 'Select link kind',
          es: 'Seleccione el tipo de enlace',
          pt: 'Selecione o tipo de linkr'
        },
        chart: {
          en: 'Chart',
          es: 'Gráfico',
          pt: 'Gráfico'
        },
        workspace: {
          en: 'Workspace',
          es: 'Espacio de trabajo',
          pt: 'Espaço de trabalho'
        },
        button: {
          en: 'Add link',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        add: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar',
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      filtersSpecific: {
        labelInput: {
          en: 'Add label',
          es: 'Añadir etiqueta',
          pt: 'Adicionar rótulo'
        },
        selectDimension: {
          en: 'Select dimension',
          es: 'Seleccionar dimensión',
          pt: 'Selecionar dimensão'
        }
      }, 
      editColors: {
        label: {
          en: 'Edit color',
          es: 'Editar color',
          pt: 'Editar cor'
        },
        button: {
          en: 'Add Color',
          es: 'Añadir color',
          pt: 'Adicionar cor'
        },
        add: {
          es: 'Agregar',
          en: 'Add',
          pt: 'Adicionar',
        },
        save: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        }
      },
      addModules: {
        label: {
          en: 'Add filter on chart',
          es: 'Añadir filtro en el gráfico',
          pt: 'Adicionar filtro na tabela'
        },
        options: {
          dateRange: {
            en: 'Date range',
            es: 'Rango de fechas',
            pt: 'Faixa de datas'
          },
          addFilter: {
            en: 'Add filter',
            es: 'Añadir filtro',
            pt: 'Adicionar filtro'
          },
          compareToPast: {
            en: 'Compare to past',
            es: 'Comparar con el pasado',
            pt: 'Comparar com o passado'
          },
          granularity: {
            es: 'Granularidad',
            en: 'Granularity',
            pt: 'Granularidade'
          },
          csvDownload: {
            es: 'Descarga de CSV',
            en: 'CSV download',
            pt: 'CSV download'
          },
          custom: {
            en: 'Custom',
            es: 'Personalizado',
            pt: 'Personalizado'
          },
        }
      },
      order: {
        label: {
          es: 'Orden',
          en: 'Order',
          pt: 'Ordem'
        },
        button: {
          es: 'Añadir orden',
          en: 'Add order',
          pt: 'Adicionar ordem'
        },
      },
      limit: {
        label: {
          es: 'Límite',
          en: 'Limit',
          pt: 'Limite'
        },
        button: {
          es: 'Añadir consulta límite',
          en: 'Add limit query',
          pt: 'Adicionar consulta de limite'
        },
        maxItemsShow: {
          es: 'Añadir tabla de páginas límite',
          en: 'Max items show',
          pt: 'Adicionar tabela de página de limite'
        },
        placeholder: {
          es: 'Guardar',
          en: 'Limit',
          pt: 'Salvar'
        },
        save: {
          es: 'Límite',
          en: 'Limit',
          pt: 'Limite'
        }
      },
      download: {
        buttonDownload: {
          es: 'Exportar como CSV',
          en: 'Export as CSV',
          pt: 'Exportar como CSV'
        },
      },
      add: {
        label: {
          es: 'Filtro',
          en: 'Filter',
          pt: 'Filtro'
        },
        filterTitle: {
          es: 'Agregar más filtros',
          en: 'Add more filters',
          pt: 'Adicionar mais filtros'
        },
        buttonApply: {
          es: 'Aplicar filtros',
          en: 'Apply filters',
          pt: 'Aplicar filtros',
        },
        buttonReset: {
          es: 'Borrar filtros',
          en: 'Reset filters',
          pt: 'Limpiar filtros',
        },
        buttonAdd: {
          es: 'Agregar más filtros',
          en: 'Add more filters',
          pt: 'Adicionar mais filtros'
        },
        buttonSave:  {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        actionPlaceholder: {
          es: 'Seleccionar función',
          en: 'Select function',
          pt: 'Selecione a função'
        },
        filterPlaceholder: {
          es: 'Agregar filtros',
          en: 'Add filters',
          pt: 'Adicionar filtross'
        },
        filterKeyPlaceholder: {
          es: 'Seleccionar palabra clave',
          en: 'Select filter key',
          pt: 'Selecione palavra-chave'
        },
        valuePlaceholder: {
          es: 'Ingrese el valor',
          en: 'Add the value',
          pt: 'Acrescente o valor'
        },
        filterLabel: {
          keyword: {
            es: 'Keyword',
            en: 'Keyword',
            pt: 'Keyword'
          },
          funcOp: {
            es: 'Función',
            en: 'Function',
            pt: 'Função'
          },
          value: {
            es: 'Valor',
            en: 'Value',
            pt: 'Valor'
          }
        },
        filterSubtitle: {
          addNew: {
            es: 'Filtrar por',
            en: 'Filter by',
            pt: 'Filtrar por'
          },
          filtered: {
            es: 'Filtrado por',
            en: 'Filtered by',
            pt: 'Filtrado por'
          },
        }
      },
      measures: {
        label: {
          en: 'Measures',
          es: 'Medidas',
          pt: 'Medidas'
        },
        placeholder: {
          en: 'Select measures',
          es: 'Seleccione las medidas',
          pt: 'Selecione as medidas'
        }
      },
      granularity: {
        label: {
          es: 'Granularidad',
          en: 'Granularity',
          pt: 'Granularidade'
        },
        placeholder: {
          en: 'Select granularity',
          es: 'Seleccione la granularidad',
          pt: 'Selecione a granularidade'
        },
        options: {
          // second: {
          //   en: 'Second',
          //   es: 'Segundo',
          //   pt: 'Segundo'
          // },
          // minute: {
          //   en: 'Minute',
          //   es: 'Minuto',
          //   pt: 'Minuto'
          // },
          hour: {
            en: 'Hour',
            es: 'Hora',
            pt: 'Hora'
          },
          day: {
            en: 'Day',
            es: 'Día',
            pt: 'Dia'
          },
          week: {
            en: 'Week',
            es: 'Semana',
            pt: 'Semana'
          },
          month: {
            en: 'Month',
            es: 'Mes',
            pt: 'Mês'
          },
          quarter: {
            en: 'Quarter',
            es: 'Quarter',
            pt: 'Trimestre'
          },
          year: {
            en: 'Year',
            es: 'Año',
            pt: 'Ano'
          },
          'w/o grouping': {
            en: 'W/O Grouping',
            es: 'W/O Grouping',
            pt: 'W/O Grouping'
          }
        }
      },
      dimensions: {
        label: {
          en: 'Dimension',
          es: 'Dimensión',
          pt: 'Dimensão'
        },
        placeholder: {
          en: 'Select dimension',
          es: 'Seleccione la dimensión',
          pt: 'Selecionar dimensão'
        }
      },
      chartFormula: {
        label: {
          en: 'Chart embedded',
          es: 'Gráfico integrados',
          pt: 'Gráfico embutido'
        },
        button: {
          en: 'Add chart embedded',
          es: 'Añadir gráfico integrados',
          pt: 'Adicionar gráfico embutido'
        },
        buttonSave: {
          es: 'Guardar',
          en: 'Save',
          pt: 'Salvar'
        },
        labelName: {
          en: 'Name',
          es: 'Nombre',
          pt: 'Nome '
        },
        labelFormula: {
          en: 'Formula',
          es: 'Fórmula',
          pt: 'Fórmula'
        },
        options: {
          count: {
            en: 'Count',
            es: 'Contar',
            pt: 'Conde'
          },
          rate_percentage: {
            en: 'Rate percentage',
            es: 'Porcentaje del índice',
            pt: 'Taxa percentual'
          },
          rate_inverted: {
            en: 'Rate inverted',
            es: 'Tasa invertida',
            pt: 'Taxa invertida'
          }
        }
      },
      chartsTypes: {
        label: {
          en: 'Chart type',
          es: 'Tipo de gráfico',
          pt: 'Tipo de gráfico'
        },
        placeholder: {
          en: 'Select chart type',
          es: 'Seleccione el tipo de gráfico',
          pt: 'Selecione o tipo de gráfico'
        },
        options: {
          line: {
            en: 'Line',
            es: 'Línea',
            pt: 'Linha'
          },
          area: {
            en: 'Area',
            es: 'Área',
            pt: 'Área'
          },
          bar: {
            en: 'Bar',
            es: 'Barra',
            pt: 'Bar'
          },
          pie: {
            en: 'Pie',
            es: 'Pastel',
            pt: 'Torta'
          },
          heatmap: {
            en: 'Heatmap',
            es: 'Mapa de calor',
            pt: 'Mapa do calor'
          },
          heatmapCategory: {
            en: 'Heatmap category',
            es: 'Mapa de calor de categorías',
            pt: 'Mapa do calor de categorias'
          },
          barcategory: {
            en: 'Bar category',
            es: 'Barra de categorías',
            pt: 'Barra de categorias'
          },
          totalHits: {
            en: 'Total hits',
            es: 'Total hits',
            pt: 'Total hits'
          },
          totalTable: {
            en: 'Total table',
            es: 'Total table',
            pt: 'Total table'
          },
          totalTableMany: {
            en: 'Total table 2',
            es: 'Total table 2',
            pt: 'Total table 2'
          },
          totalTablePerformance: {
            en: 'Total Performance links',
            es: 'Total Performance links',
            pt: 'Total Performance links'
          },
          conversationBreakdown: {
            en: 'Conversation Breakdown',
            es: 'Conversation Breakdown',
            pt: 'Conversation Breakdown'
          },
          containmentRate: {
            en: 'Containment rate',
            es: 'Tasa de contención',
            pt: 'Taxa de contenção'
          },
          table: {
            en: 'Table',
            es: 'Tabla',
            pt: 'Tabela'
          },
          number: {
            en: 'Number',
            es: 'Número',
            pt: 'Número'
          },
          gauge: {
            en: 'Gauge',
            es: 'Indicador',
            pt: 'Medidor'
          },
          funnel: {
            en: 'Funnel',
            es: 'Embudo',
            pt: 'Funil'
          }
        }
      },
      timeDimensions: {
        label: {
          en: 'Time dimensions',
          es: 'Dimensiones de tiempo',
          pt: 'Dimensões temporais'
        },
        placeholder: {
          en: 'Select time dimensions',
          es: 'Seleccione las dimensiones de tiempo',
          pt: 'Selecionar dimensões de tempo'
        }
      },
      dateRange: {
        label: {
          en: 'Date range',
          es: 'Rango de fechas',
          pt: 'Faixa de datas'
        },
        placeholder: {
          en: 'Select date range',
          es: 'Seleccione el rango de fechas',
          pt: 'Selecione o intervalo de datas'
        },
        options: {
          today: {
            en: 'Today',
            es: 'Hoy',
            pt: 'Hoje'
          },
          fromYesterdayToNow: {
            en: 'from yesterday',
            es: 'from yesterday',
            pt: 'from yesterday'
          },
          fromLastQuarterToNow: {
            en: 'from previous quarter',
            es: 'from previous quarter',
            pt: 'from previous quarter'
          },
          fromLast2DaysToYesterday: {
            en: 'from previous yesterday',
            es: 'from previous yesterday',
            pt: 'from previous yesterday'
          },
          fromLastWeekToNow: {
            en: 'from previous week',
            es: 'from previous week',
            pt: 'from previous week'
          },
          fromLastYearToNow: {
            en: 'from previous year',
            es: 'from previous year',
            pt: 'from previous year'
          },
          from60DaysToLast30Days: {
            en: 'from previous 30 days',
            es: 'from previous 30 days',
            pt: 'from previous 30 days'
          },
          fromLast2YearToLastYear: {
            en: 'from previous year',
            es: 'from previous year',
            pt: 'from previous year'
          },
          yesterday: {
            en: 'Yesterday',
            es: 'Ayer',
            pt: 'Ontem'
          },
          'from 7 days ago to now': {
            en: 'Last 7 days',
            es: 'Últimos 7 días',
            pt: 'Últimos 7 dias'
          },
          'from 14 days ago to now': {
            en: 'Last 14 days',
            es: 'Últimos 14 días',
            pt: 'Últimos 14 dias'
          },
          fromPreviousSevenDays: {
            en: 'from previous 7 days',
            es: 'de los 7 días anteriores',
            pt: 'dos 7 dias anteriores'
          },
          fromLastMonth: {
            en: 'from last month',
            es: 'del mes pasado',
            pt: 'do mês passado'
          },
          'this week': {
            en: 'This week',
            es: 'Esta semana',
            pt: 'Nesta semana'
          },
          'this month': {
            en: 'This month',
            es: 'Este mes',
            pt: 'Este mês'
          },
          'this quarter': {
            en: 'This quarter',
            es: 'Este trimestre',
            pt: 'Neste trimestre'
          },
          'this year': {
            en: 'This year',
            es: 'Este año',
            pt: 'Neste ano'
          },
          'from 30 days ago to now': {
            en: 'Last 30 days',
            es: 'Últimos 30 días',
            pt: 'Últimos 30 dias'
          },
          'last year': {
            en: 'Last year',
            es: 'El año pasado',
            pt: 'Ano passado'
          }
        },
        labelCompareDate: {
          'from yesterday to today': {
            en: 'from yesterday',
            es: 'de ayer',
            pt: 'de ontem'
          },
          'from last 2 days to yesterday': {
            en: 'from before yesterday',
            es: 'de antes de ayer',
            pt: 'de antes de ontem'
          },
          'from 14 days ago to 8 days ago': {
            en: 'from previous 7 days',
            es: 'de los 7 días anteriores',
            pt: 'dos 7 dias anteriores'
          },
          'from 28 days ago to 15 days ago': {
            en: 'from previous 14 days',
            es: 'de los 14 días anteriores',
            pt: 'dos 14 dias anteriores'
          },
          'from last week to this week': {
            en: 'from previous week days',
            es: 'de días de la semana anterior',
            pt: 'dos dias da semana anterior'
          },
          'from last quarter to this quarter': {
            en: 'from previous quarter',
            es: 'del trimestre anterior',
            pt: 'do trimestre anterior'
          },
          'from last year to now': {
            en: 'from previous year',
            es: 'del año anterior',
            pt: 'do ano anterior'
          },
          'from 60 days to last 30 days': {
            en: 'from previous 30 days',
            es: 'de los 30 días anteriores',
            pt: 'dos 30 dias anteriores'
          },
          'from last 2 years to last year': {
            en: 'from previous year',
            es: 'del año anterior',
            pt: 'do ano anterior'
          },
          'customDate': {
            from: {
              es: 'desde',
              en: 'from',
              pt: 'desde'
            },
            to: {
              es: 'hasta',
              en: 'to',
              pt: 'até'
            }
          }
        }
      }
    },
    chartBox : {
      TotalShownLinks: {
        es: 'Total de enlaces mostrados',
        en: 'Total links shown',
        pt: 'Total dos links mostrados'
      },
      TotalClicks: {
        es: 'Total de clics',
        en: 'Total clicks',
        pt: 'Total de cliques'
      },
      ClickRate: {
        es: 'Tasa de clics',
        en: 'Click rate',
        pt: 'Taxa de cliques'
      }
    }
  },
  searchChart: {
    es: 'Buscar un gráfico',
    en: 'Search for a chart',
    pt: 'Procurar um gráfico'
  },
  errorLoadingChart: {
    en: 'Error loading chart',
    es: 'Error al cargar el gráfico',
    pt: 'Erro ao carregar o gráfico'
  },
  deleteConfirmation: {
    title: {
      es: 'Confirmación',
      en: 'Confirmation',
      pt: 'Confirmação'
    },
    text: {
      es: '¿Estás seguro de querer eliminar esta gráfica?',
      en: 'Are you sure you want to delete this chart?',
      pt: 'Tem certeza de que deseja excluir este gráfico?'
    },
    acceptText: {
      es: 'Sí, eliminar esta gráfica',
      en: 'Yes, delete this chart',
      pt: 'Sim, exclua este gráfico'
    },
    cancelText: {
      es: 'No',
      en: 'No',
      pt: 'Não'
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'El gráfico se eliminó con éxito.',
        en: 'The chart was successfully removed.',
        pt: 'O gráfico foi removido com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    }
  },
  popUpTitle: {
    add: {
      es: 'Crear gráfico',
      en: 'Create chart',
      pt: 'Criar gráfico'
    }
  },
  cancelText: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  saveText: {
    es: 'Guardar',
    en: 'Save',
    pt: 'Salvar'
  },
  add: {
    success: {
      title: {
        es: 'Chart Created',
        en: 'Chart Created',
        pt: 'Chart Created'
      },
      text: {
        es: 'Chart Created',
        en: 'Chart Created',
        pt: 'Chart Created'
      }
    }
  },
  analytics: {
    chartsDefault: {
      table: {
        tooltip: {
          Negativereviewincreased: {
            en: 'Negative review increased',
            es: 'Aumento de las críticas negativas',
            pt: 'Aumento das avaliações negativas'
          },
          Negativereviewdecreased: {
            en: 'Negative review decreased',
            es: 'Disminución de las críticas negativas',
            pt: 'A revisão negativa diminuiu'
          }
        }
      }
    },
    LinkTracking: {
      es: 'Seguimiento de enlaces',
      en: 'Link Tracking',
      pt: 'Rastreamento de links'
    },
    'AnswerPerformanceAndHand-off': {
      es: 'Respuesta Rendimiento y entrega',
      en: 'Answer Performance and Hand-off',
      pt: 'Desempenho e entrega da resposta'
    },
    InteractionRate: {
      es: 'Tasa de interacción',
      en: 'Interaction rate',
      pt: 'Taxa de interação'
    },
    ProactiveMessageVolume: {
      es: 'Volumen de mensajes proactivos',
      en: 'Proactive message volume',
      pt: 'Volume de mensagens proativas'
    },
    AnswerMoreTriggered: {
      es: 'Respuestas mas mostradas',
      en: 'Answer more triggered',
      pt: 'Resposta mais acionada'
    },
    AgentAttentionRequired: {
      es: 'Atención del agente requerida',
      en: 'Agent attention required',
      pt: 'Atenção do agente requerida'
    },
    ProactiveMessagesTriggered: {
      es: 'Mensajes proactivos activados',
      en: 'Proactive messages triggered',
      pt: 'Mensagens proativas acionadas'
    },
    ProactiveReport: {
      es: 'Informe Proactivo',
      en: 'Proactive Report',
      pt: 'Relatório Proativo'
    },
    MessageVolume: {
      es: 'Volumen de mensajes',
      en: 'Message volume',
      pt: 'Volume da mensagem'
    },
    PositiveFeedback: {
      es: 'Comentarios positivos',
      en: 'Positive feedback',
      pt: 'Feedback positivo'
    },
    Conversations: {
      es: 'Conversaciones',
      en: 'Conversations',
      pt: 'Conversações'
    },
    ContainmentRate: {
      es: 'Tasa de contención',
      en: 'Containment rate',
      pt: 'Taxa de contenção'
    },
    AnswersWithTheMostFeedback: {
      es: 'Respuestas más valoradas',
      en: 'Answers with the most feedback',
      pt: 'Respostas com mais feedback'
    },
    FrequencyAndContainmentRate: {
      es: 'Frecuencia y Tasa de Contención',
      en: 'Frequency and Containment Rate',
      pt: 'Frequência e Taxa de Contenção'
    },
    Categories: {
      es: 'Categorías',
      en: 'Categories',
      pt: 'Categorias'
    },
    MostDislikedAnswers: {
      es: 'Las respuestas que menos gustan',
      en: 'Most disliked answers',
      pt: 'Respostas que não gostaram mais'
    },
    NotDetectedTopics: {
      es: 'Temas no detectados',
      en: 'Not detected topics',
      pt: 'Tópicos não detectados'
    },
    infoTitle: {
      InteractionRate: {
        es: 'Porcentaje que representa la frecuencia con la que los usuarios interactúan tras recibir un mensaje proactivo.',
        en: 'Percentage representing the frequency with which users interact after receiving a proactive message.',
        pt: 'Percentagem que representa a frequência com que os usuários interagem após receber uma mensagem proativa.'
      },
      ProactiveMessageVolume: {
        es: 'Mide el número de mensajes proactivos de los usuarios por hora y día de la semana (la hora está en la zona horaria local del navegador).',
        en: 'Measures the number of proactive messages from users by hour and day of the week (time is in the local time zone of the browser).',
        pt: 'Mede o número de mensagens proativas dos usuários por hora e dia da semana (o horário está no fuso horário local do navegador).'
      },
      ContainmentRate: {
        es: 'Mide el porcentaje de interacciones que no llegan a un agente humano.',
        en: 'Measures the percentage of interactions not reaching a human agent.',
        pt: 'Mede a porcentagem de interações que não chegam a um agente humano.'
      },    
      ProactiveAgentAttentionRequired: {
        es: 'Sesiones en las que el agente intervino tras un mensaje proactivo.',
        en: 'Sessions where the agent intervened following a proactive message.',
        pt: 'Sessões em que o agente interveio após uma mensagem proativa.'
      },   
      AgentAttentionRequired: {
        es: 'Muestra sesiones que alcanzaron el control humano sin que los usuarios lo solicitaran.',
        en: 'Shows sessions that reached human takeover without users request.',
        pt: 'Mostra sessões que foram controladas por humanos sem solicitação dos usuários.'
      },   
      ProactiveReport: {
        es: 'Informe sobre las respuestas ejecutadas en mensajes proactivos, identificando usuarios, fechas clave de interacción y frecuencia de dichas respuestas.',
        en: 'Report on answers executed in proactive messages, identifying users, key interaction dates, and frequency of such answers.',
        pt: 'Relatório sobre respostas executadas em mensagens proativas, identificando usuários, datas-chave de interação e frequência dessas respostas.'
      },
      Conversations: {
        es: 'Número de conversaciones que el bot o los bots mantienen en un periodo de tiempo.',
        en: 'Number of conversations the bot(s) have in a period of time.',
        pt: 'Número de conversas que o(s) bot(s) tem em um período de tempo.'
      },
      MessageVolume: {
        es: 'Mide el número de mensajes de los usuarios por hora y día de la semana (la hora está en la zona horaria local del navegador).',
        en: 'Measures the number of messages from users by hour and day of the week (time is in the local time zone of the browser).',
        pt: 'Mede o número de mensagens dos usuários por hora e dia da semana (o horário está no fuso horário local do navegador).'
      },
      AnswerMoreTriggered: {
        es: 'Muestra las respuestas que cumplen con mayor frecuencia las reglas configuradas.',
        en: 'Displays answers triggered most frequently by the configured rules.',
        pt: 'Mostra as respostas acionadas com mais frequência pelas regras configuradas.'
      },
      AnswersWithTheMostFeedback: {
        es: 'Muestra las respuestas con más comentarios, excluyendo los saludos iniciales y finales.',
        en: 'Displays answers with the most feedback, excluding initial and final greetings.',
        pt: 'Exibe as respostas com o maior número de comentários, excluindo as saudações iniciais e finais.'
      },
      FrequencyAndContainmentRate: {
        es: 'Frecuencia es el número de veces que una respuesta se repite a lo largo del tiempo. La Tasa de Contención muestra cuántos usuarios ayuda un chatbot sin necesidad de intervención humana.',
        en: 'Frequency is the number of times an answer is repeated over time. Containment Rate shows how many users a chatbot helps without needing human intervention.',
        pt: 'Frequência é o número de vezes que uma resposta é repetida ao longo do tempo. A Taxa de Contenção mostra quantos usuários um chatbot ajuda sem precisar de intervenção humana.'
      },
      Categories: {
        es: 'Agrupa las etiquetas más referenciadas por categorías.',
        en: 'Groups the most referenced tags by categories.',
        pt: 'Agrupa as tags mais referenciadas por categorias.'
      },
      NotDetectedTopics: {
        es: 'Agrupa por temas las entradas de usuario que no se han podido detectar.',
        en: 'Groups by topic the user input that could not be detected.',
        pt: 'Agrupa por tópico a entrada do usuário que não pôde ser detectada.'
      },     
    },
    headers: {
      AnswerName: {
        es: 'Nombre de Respuesta',
        en: 'Answer Name',
        pt: 'Nome da Resposta'
      },
      UserIdentifier: {
        es: 'Identificador de Usuario',
        en: 'User Identifier',
        pt: 'Identificador do Usuário'
      },
      'Frecuency(%)': {
        es: 'Frecuencia(%)',
        en: 'Frequency(%)',
        pt: 'Frequência(%)'
      },
      DisplayDate: {
        es: 'Fecha de visualización',
        en: 'Display date',
        pt: 'Data de exibição'
      },
      InteractionDate: {
        es: 'Fecha de interacción',
        en: 'Interaction date',
        pt: 'Data de interação'
      },
      TakeOverDate: {
        es: 'Fecha de interacción del agente',
        en: 'Agent interaction date',
        pt: 'Data de interação do agente'
      },
      AnswerHits: {
        es: 'Veces que se ejecutó la respuesta',
        en: 'Times answer was executed',
        pt: 'Vezes que a resposta foi executada'
      },   
      URL: {
        es: 'URL',
        en: 'URL',
        pt: 'URL'
      },
      Answer: {
        es: 'Respuesta',
        en: 'Answer',
        pt: 'Resposta'
      },
      Shown: {
        es: 'Mostrado',
        en: 'Shown',
        pt: 'Mostrado'
      },
      Clicked: {
        es: 'Pulsado',
        en: 'Clicked',
        pt: 'Clicado'
      },
      ClickRate: {
        es: 'Tasa de clics',
        en: 'Click Rate',
        pt: 'Taxa de cliques'
      },
      Answers: {
        es: 'Respuestas',
        en: 'Answers',
        pt: 'Respostas'
      },
      NegativeReviewRate: {
        es: 'Tasa de reseñas negativas',
        en: 'Negative review rate',
        pt: 'Taxa de avaliações negativas'
      },
      NegativeReview: {
        es: 'Revisión negativa',
        en: 'Negative review',
        pt: 'Avaliação negativa'
      },
      PositiveReviewRate: {
        es: 'Tasa de reseñas positivas',
        en: 'Positive review rate',
        pt: 'Taxa de avaliação positiva'
      },
      PositiveReview: {
        es: 'Evaluación positiva',
        en: 'Positive review',
        pt: 'Avaliação positiva'
      },
      HandoffRate: {
        es: 'Tasa de transferencia',
        en: 'Handoff Rate',
        pt: 'Taxa de transferência'
      },
      PercentOfTotalHandoffs: {
        es: 'Porcentaje del total de transferencias',
        en: 'Percent of total handoffs',
        pt: 'Porcentagem do total de transferências'
      },
      TotalHandoffs: {
        pt: 'Total de transferências',
        en: 'Total Handoffs',
        es: 'Total de transferencias'
      },
      Topics: {
        es: 'Temas',
        en: 'Topics',
        pt: 'Tópicos'
      },
      Quantity: {
        es: 'Cantidad',
        en: 'Quantity',
        pt: 'Quantidade'
      }
    },
    titles: {
      ProactiveMessagesMetricAnalytics: {
        es: 'Métricas analíticas de mensajes proactivos',
        en: 'Proactive messages metric analytics',
        pt: 'Métricas analíticas de mensagens proativas'
      },
      'AnswerPerformanceAndHand-off': {
        es: 'Respuesta Rendimiento y entrega',
        en: 'Answer Performance and Hand-off',
        pt: 'Desempenho e entrega da resposta'
      },
      ProactiveReport: {
        es: 'Informe Proactivo',
        en: 'Proactive Report',
        pt: 'Relatório Proativo'
      },
      ConversationMetricsAnalytics: {
        es: 'Analítica de las métricas de conversación',
        en: 'Conversation metrics analytics',
        pt: 'Análise de métricas de conversação'
      },
      AnswerMetricAnalytics: {
        es: 'Analítica de las métricas de respuesta',
        en: 'Answer metric analytics',
        pt: 'Análise de métricas de resposta'
      }
    },
    funnel: {
      WebAgentattentionvwMessagecount: {
        es: 'Total de sesiones',
        en: 'Total sessions',
        pt: 'Total de sessões'
      },
      WebAgentattentionvwTakeovercount: {
        es: 'Sesiones tomadas por el agente',
        en: 'Sessions taken by agent',
        pt: 'Sessões assumidas pelo agente'
      },
      WebAgentattentionfullvwMessagecount: {
        es: 'Total de sesiones',
        en: 'Total sessions',
        pt: 'Total de sessões'
      },
      WebAnswersProactiveTakeovercount: {
        es: 'Sesiones tomadas por el agente',
        en: 'Sessions taken by agent',
        pt: 'Sessões assumidas pelo agente'
      },
      WebAnswersproactiveTotalcount: {
        es: 'Total de sesiones',
        en: 'Total sessions',
        pt: 'Total de sessões'
      },
      WebAnswersproactiveAgenttake: {
        es: 'Sesiones tomadas por el agente',
        en: 'Sessions taken by agent',
        pt: 'Sessões assumidas pelo agente'
      },
      WebConversationsSessions: {
        es: 'Total de sesiones',
        en: 'Total sessions',
        pt: 'Total de sessões'
      },
      WebConversationsTakeovercount: {  
        es: 'Sesiones tomadas por el agente',
        en: 'Sessions taken by agent',
        pt: 'Sessões assumidas pelo agente'
      },
      WebAgentattentionfullvwSessionidcount: {
        es: 'Total de sesiones',
        en: 'Total sessions',
        pt: 'Total de sessões'
      },
      WebAgentattentionfullvwTakeovercount: {
        es: 'Sesiones tomadas por el agente',
        en: 'Sessions taken by agent',
        pt: 'Sessões assumidas pelo agente'
      }
    },
    barCategory: {
      ProactivevwCount: {
        es: 'Número de veces que una respuesta fue activada',
        en: 'Number of times an answer was triggered',
        pt: 'Número de vezes que uma resposta foi acionada'
      }
    },
    heatMap: {
      titleBar: {
        es: 'Volumen total de mensajes',
        en: 'Total message volume',
        pt: 'Volume total de mensagens'
      }
    }
  },
}
