export default {
  title: {
    es: 'No se puede eliminar esta variable sensible',
    en: 'Cannot delete this sensitive variable',
    pt: 'Não é possível deletar esta variável confidencial'
  },
  textAnswers: {
    es: 'La variable no puede ser eliminada porque está asociada con ',
    en: 'The variable cannot be deleted because it is associated with ',
    pt: 'A variável não pode ser deletada porque está associada a '
  },
  textApiCalls: {
    es: 'La variable no puede ser eliminada porque está asociada con ',
    en: 'The variable cannot be deleted because it is associated with ',
    pt: 'A variável não pode ser deletada porque está associada a '
  },
  answer: {
    es: 'respuesta(s).',
    en: 'answer(s).',
    pt: 'resposta(s).'
  },
  apiCalls: {
    es: 'API call(s).',
    en: 'API call(s).',
    pt: 'API call(s).'
  },
  answersShow: {
    es: 'Mostrar respuesta(s) asociada(s)',
    en: 'Show answer(s) associated',
    pt: 'Mostrar resposta(s) associada(s)'
  },
  answersHide: {
    es: 'Ocultar respuesta(s) asociada(s)',
    en: 'Hide answer(s) associated',
    pt: 'Ocultar resposta(s) associada(s)'
  },
  apiCallsShow: {
    es: 'Mostrar API call(s) asociada(s)',
    en: 'Show API call(s) associated',
    pt: 'Mostrar API call(s) associada(s)'
  },
  apiCallsHide: {
    es: 'Ocultar API call(s) asociada(s)',
    en: 'Hide API call(s) associated',
    pt: 'Ocultar API call(s) associada(s)'
  },
  button: {
    ok: {
      es: 'Entendido, lo tengo',
      en: 'Ok, I get it',
      pt: 'Ok, eu entendi'
    },
    delete: {
      es: 'Eliminar de todos modos',
      en: 'Delete anyway',
      pt: 'Eliminar de qualquer forma'
    }
  }
}
