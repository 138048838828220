/**
 * Is String
 * @param value {Object} the value to be detected
 * @returns {boolean}
 * @private
 */
function _isString(value) {
  return typeof value === 'string' || value instanceof String
}

/**
 * Is an array
 * @returns {*|boolean}
 * @private
 * @param value {Object}
 */
function _isArray(value) {
  return value && typeof value === 'object' && value.constructor === Array
}

/**
 * Validate if the object is empty
 * @param {Object} value the object to be evaluated
 * @returns {Boolean}
 */
const _isEmpty = function(value) {
  if (_isString(value)) {
    return value.length === 0 && value.replace(/\s/g, '').length === 0
  }

  if (_isArray(value)) {
    return value === undefined || value.length === 0
  }
}

/**
 * Validate email address
 * @param {String} value the email address to be validated
 * @returns {Boolean}
 */
const _isValidEmail = function(value) {
  if (_isString(value)) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(value).toLowerCase())
  }

  return false
}

export default {
  isValidEmail: _isValidEmail,
  isEmpty: _isEmpty
}
