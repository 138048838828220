import Request from './request'
import state from '../store/state'

const PATHS = {
  GET_ALL: '/answer_component'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = companyId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
    params: {
      'where[company]': companyId
    }
  }
  return Request.get(`${state.config.konecta}${PATHS.GET_ALL}`, config)
}

export default {
  getAll
}
