const actions = {
  generateScreenPermissions({ commit }, sidebarItems) {
    const extractPermissions = (items) => {
      return items.reduce((acc, item) => {
        if (item.url) {
          const baseRoute = `/${item.url.split('/')[1]}`;
          acc[baseRoute] = item.roles || [];
        }
        if (item.submenu) {
          Object.assign(acc, extractPermissions(item.submenu));
        }
        return acc;
      }, {});
    };

    const permissions = extractPermissions(sidebarItems);
    commit('SET_SCREEN_PERMISSIONS', permissions);
  }
};

export default actions;
