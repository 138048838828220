
import IpRangesService from '@/services/ipRanges.service'

/**
 * @type {import('vuex').Module}
 */
export default {
  namespaced: true,
  state: {
    ipRanges: []
  },
  mutations: {
    CREATE_IP_RANGE(state, ipRange) {
      state.ipRanges = [ipRange].concat(state.ipRanges)
    },
    UPDATE_IP_RANGE(state, ipRange) {
      let index = null
      state.ipRanges.some((element, i) => {
        if (element._id === ipRange._id) {
          index = i
        }
        return index !== null
      })
      if (index !== null) {
        state.ipRanges[index] = ipRange
        const aux = JSON.parse(JSON.stringify(state.ipRanges))
        state.ipRanges = aux
      }
    },
    DELETE_IP_RANGE(state, ipRange) {
      let index = null
      state.ipRanges.some((element, i) => {
        if (element._id === ipRange._id) {
          index = i
        }
        return index !== null
      })
      if (index !== null) {
        state.ipRanges.splice(index, 1)
      }
    }
  },
  actions: {
    async GET_IP_RANGE({ state }) {
      return IpRangesService.getIpRanges()
        .then(response => { state.ipRanges = response.data })
        .catch(error => error)
    },
    async CREATE_IP_RANGE({ commit }, message) {
      return IpRangesService.createIpRange(message.body)
        .then(response => {
          commit('CREATE_IP_RANGE', response.data)
          return response.data
        })
        .catch(error => {
          throw error
        })
    },
    async UPDATE_IP_RANGE({ commit }, message) {
      return IpRangesService.updateIpRange(message._id, message.body)
        .then(response => {
          commit('UPDATE_IP_RANGE', response.data)
          return response.data
        })
        .catch(error => {
          throw error
        })
    },
    async DELETE_IP_RANGE({ commit }, message) {
      return IpRangesService.deleteIpRange(message._id)
        .then(response => {
          commit('DELETE_IP_RANGE', response.data)
          return response.data
        })
        .catch(error => {
          throw error
        })
    }
  },
}