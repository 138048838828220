import { USER_ROLES } from './UserRoles'

export class RoleCreationCapability {
  /**
   * Role creation capability
   * @param roles {Array<String>}
   */
  constructor(roles) {
    this._editor = false
    this._supervisor = false
    this._agent = false
    this._rolesManager = false
    this._guest = false

    if (roles.length > 0) {
      if (roles.includes(USER_ROLES.SUPER_ADMIN)) {
        this._editor = true
        this._supervisor = true
        this._agent = true
        this._rolesManager = true
        this._guest = true
      }

      if (roles.includes(USER_ROLES.ROLES_MANAGER)) {
        this._editor = true
        this._supervisor = true
        this._agent = true
        this._guest = true
      }
    }
  }

  get editor() {
    return this._editor
  }

  get supervisor() {
    return this._supervisor
  }

  get agent() {
    return this._agent
  }

  get rolesManager() {
    return this._rolesManager
  }

  get guest() {
    return this._guest
  }
}
