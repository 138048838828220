import Vue from 'vue'

const INTENTION_REGEX = /[A-Za-z0-9-_\s]/

// Intention name allow only letters, numbers, middle dash, underscore & whitespaces
Vue.directive('intention', {
  bind: function(el) {
    const handler = function(e) {
      if (!e.key.match(INTENTION_REGEX) && !e.altKey) {
        e.preventDefault()
      }
    }
    el.addEventListener('keypress', handler)
  }
})

Vue.directive('focus', {
  // Cuando el elemento enlazado se inserta en el DOM...
  inserted: function(el) {
    if (el.querySelector('p.tribute-input')) {
      el.querySelector('p.tribute-input').focus()
    } else if (el.tagName === 'DIV') {
      el.querySelector('input').focus()
    } else {
      el.focus()
    }
  }
})

Vue.directive('click-outside', {
  bind: function(el, binding) {
    const bubble = binding.modifiers.bubble
    const handler = e => {
      if (bubble || (!el.contains(e.target) && el !== e.target)) {
        binding.value(e)
      }
    }
    el.__vueClickOutside__ = handler
    document.addEventListener('click', handler)
  },

  unbind: function(el) {
    document.removeEventListener('click', el.__vueClickOutside__)
    el.__vueClickOutside__ = null
  }
})