import { USER_ROLES } from '../../src/models/Roles/UserRoles';

export const PASSWORD_VALIDATION_VALUES = {
  NUMBER_PASSWORD_HISTORY: 5,
  MIN_LENGTH_ADMIN: 16,
  MIN_LENGTH_NORMAL: 12,
  MAX_LENGTH: 64
}

export const VARIABLES_TYPES = {
  STRING: 'String',
  DATE: 'Date',
  NUMBER: 'Number',
  LIST: 'List',
}

export const VARIABLES_DATA_TYPES = {
  PROACTIVE:'Proactive',
  SENSITIVE: 'Sensitive',
}

export const ANSWER_MODES = {
  VIEW: 'view',
  EDIT: 'edit'
}

export const CHARTS_TYPES = {
  LINE: 'line',
  BAR: 'bar',
  TABLE: 'table',
  // COLUMN: 'column',
  PIE: 'pie',
  AREA: 'area',
  NUMBER: 'number',
  HEATMAP: 'heatmap',
  BARCATEGORY: 'barcategory',
  GAUGE: 'gauge',
  FUNNEL: 'funnel',
  HEATMAPCATEGORY: 'heatmapCategory'
}

export const CHARTS_TABLE_MAX_LIMIT = 10

export const FILTER_MODULES = {
  DATE_RANGE: 'dateRange',
  ADD_FILTER: 'addFilter',
  GRANULARITY: 'granularity',
  COMPARE_TO_PAST: 'compareToPast',
  CSV_DOWNLOAD: 'csvDownload',
  CUSTOM: 'custom'
}

export const FORMULA_OPTIONS_CHARTS = {
  COUNT: 'count',
  RATE_PERCENTAGE: 'rate_percentage',
  RATE_INVERTED: 'rate_inverted'
}

const DATE_RANGES_TMP = {
  TODAY: 'today',
  YESTERDAY: 'yesterday',
  FROM_7_DAYS_AGO_TO_NOW: 'from 7 days ago to now',
  FROM_14_DAYS_AGO_TO_NOW: 'from 14 days ago to now',
  LAST_30_DAYS: 'from 30 days ago to now',
  LAST_YEAR: 'last year',
  THIS_WEEK: 'this week',
  THIS_MONTH: 'this month',
  THIS_QUARTER: 'this quarter',
  THIS_YEAR: 'this year',
}

export const DATE_RANGES = DATE_RANGES_TMP

const DATE_RANGES_COMPARE_TMP = {
  [DATE_RANGES_TMP.TODAY]: 'from yesterday to today',
  [DATE_RANGES_TMP.YESTERDAY]: 'from last 2 days to yesterday',
  [DATE_RANGES_TMP.FROM_7_DAYS_AGO_TO_NOW]: 'from 14 days ago to 8 days ago',
  [DATE_RANGES_TMP.FROM_14_DAYS_AGO_TO_NOW]: 'from 28 days ago to 15 days ago',
  [DATE_RANGES_TMP.THIS_WEEK]: 'from last week to this week',
  [DATE_RANGES_TMP.THIS_MONTH]: 'from last month to this month',
  [DATE_RANGES_TMP.THIS_QUARTER]: 'from last quarter to this quarter',
  [DATE_RANGES_TMP.THIS_YEAR]: 'from last year to now',
  [DATE_RANGES_TMP.LAST_30_DAYS]: 'from 60 days to last 30 days',
  [DATE_RANGES_TMP.LAST_YEAR]: 'from last 2 years to last year'
}

export const OPERATORS_OPTIONS = {
  EQUALS: {
    title: 'equals',
    name: 'equals'
  },
  NOT_EQUALS: {
    title: 'does not equal',
    name: 'notEquals'
  },
  SET: {
    title: 'is set',
    name: 'set'
  },
  NOT_SET: {
    title: 'is not set',
    name: 'notSet'
  },
  GREATER_THAN: {
    title: '>',
    name: 'gt'
  },
  GREATER_THAN_EQUALS: {
    title: '>=',
    name: 'gte'
  },
  LESS_THAN: {
    title: '<',
    name: 'lt'
  },
  LESS_THAN_EQUALS: {
    title: '<=',
    name: 'lte'
  }
}

export const DATE_RANGES_COMPARE_MAP = DATE_RANGES_COMPARE_TMP

export const ALLOWED_ANSWER_COMPONENTS =  [
  'RESPONSE',
  'OPTIONS',
  'CAPTURE',
  'API_CALL',
  'CONDITION',
  'TEMPLATE_CONDITION',
  'SET_VARIABLE',
  'REDIRECT',
  'OPTIONS_ANSWER',
  'OPTIONS_DYNAMIC',
]

export const PLATFORMS = [
  {
    name: 'Web',
    value: 'web',
    enabled: false
  },
  {
    name: 'Voice',
    value: 'voice',
    enabled: false
  },
  {
    name: 'Hangouts',
    value: 'hangouts',
    enabled: false
  },
]

export const USERS_PLATFORMS = [
  {
    name: 'Web',
    value: 'web'
  },
  {
    name: 'Google Assistant',
    value: 'googlSe_assistant'
  },
  {
    name: 'Voice',
    value: 'voice'
  },
]

export const TYPES_SYMBOLS = {
  intent: '#',
  entity: '@',
  regex: '',
  match: '',
  exists: 'exists',
  notExists: 'notExists',
  equal: '=',
  notEqual: '≠',
  lessThan: '<',
  lessThanOrEqual: '<=',
  greaterThan: '>',
  greaterThanOrEqual: '>=',
  contains: 'contains',
  startWith: 'startWith',
  containsNumber: 'containsNumber',
  containsIndex: 'containsIndex',
  listIsEmpty: 'listIsEmpty',
  listIsNotEmpty: 'listIsNotEmpty'
}

export const CV_FXS = [
  'exists',
  'notExists',
  'equal',
  'notEqual',
  'lessThan',
  'lessThanOrEqual',
  'greaterThan',
  'greaterThanOrEqual',
  'listIsEmpty',
  'listIsNotEmpty',
  'contains',
  'containsNumber',
  'containsIndex',
  'startWith',
  'schedule',
  'scheduleOut',
  'match'
]

export const CV_TYPES_OPS = {
  exists: 1,
  notExists: 1,
  equal: 2,
  notEqual: 2,
  lessThan: 2,
  lessThanOrEqual: 2,
  greaterThan: 2,
  greaterThanOrEqual: 2,
  contains: 2,
  containsNumber: 2,
  containsIndex: 2,
  startWith: 2,
  schedule:3,
  scheduleOut:3,
  listIsEmpty: 1,
  listIsNotEmpty: 1,
  match: 2
}

export const CV_UNARY_FXS = ['exists', 'notExists', 'ListIsEmpty', 'ListIsNotEmpty']

export const CV_TYPES_SYMBOLS = {
  ctx: '$',
  var: '',
  input: ''
}

export const CV_OPTIONS = [
  {
    type: 'type',
    text: 'exists',
    value: '',
    value2: '',
    typeOp: 1 // 1=unary, 2=binary
  },
  {
    type: 'type',
    text: 'notExists',
    value: '',
    value2: '',
    typeOp: 1
  },
  {
    type: 'type',
    text: 'equal',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'notEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'lessThan',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'lessThanOrEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'greaterThan',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'greaterThanOrEqual',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'contains',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'listIsEmpty',
    value: '',
    value2: '',
    typeOp: 1
  },
  {
    type: 'type',
    text: 'listIsNotEmpty',
    value: '',
    value2: '',
    typeOp: 1
  },
  {
    type: 'type',
    text: 'startWith',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'containsNumber',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'containsIndex',
    value: '',
    value2: '',
    typeOp: 2
  },
  {
    type: 'type',
    text: 'schedule',
    value: '',
    value2: '',
    typeOp: 3
  },
  {
    type: 'type',
    text: 'scheduleOut',
    value: '',
    value2: '',
    typeOp: 3
  },
  {
    type: 'type',
    text: 'match',
    value: '',
    value2: '',
    typeOp: 2
  },
]

export const OPS_OPTIONS = {
  intent: ['and', 'or'],
  match: ['and', 'or'],
  entity: ['and', 'or'],
  type: ['and', 'or']
}

export const CV_COMP_TYPES = ['ctx&ctx', 'ctx&var', 'ctx&input']

export const MEDIA_TYPES = [
  'image/*',
  'video/*',
  'application/pdf',
  '.doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'
]

export const IMAGES_TYPE = ['image/*']

export const FILE_FORMATS_MAP = {
  jpeg: 'image',
  jpg: 'image',
  png: 'image',
  gif: 'image',
  bmp: 'image',
  mov: 'video',
  mp4: 'video',
  H264: 'video',
  H265: 'video',
  mpeg: 'video',
  ogg: 'video',
  pdf: 'application',
  json: 'application',
  doc: 'application',
  docx: 'application',
  xls: 'application',
  xlsx: 'application',
  txt: 'application',
  csv: 'application'
}

export const MAX_FILE_SIZE = 16 * 1024 * 1024 // 16 Mb

export const CHAT_STATUS = {
  INITIAL: 0,
  PENDING: 1,
  TAKEN: 2,
  RELEASED: 3
}

export const CHAT_EXPORT_HEADERS = {
  sender: {
    es: 'Usuario',
    en: 'User',
    pt: 'Usuario'
  },
  fullname: {
    es: 'Nombre completo',
    en: 'Full Name',
    pt: 'Nome completo'
  },
  picture: {
    es: 'Imagen',
    en: 'Picture',
    pt: 'Imagen'
  },
  botName: {
    es: 'Bot',
    en: 'Bot',
    pt: 'Bot'
  },
  platform: {
    es: 'Plataforma',
    en: 'Platform',
    pt: 'Plataforma'
  },
  status: {
    es: 'Estado',
    en: 'Status',
    pt: 'Status'
  },
  _id: {
    es: 'Id',
    en: 'Id',
    pt: 'Id'
  },
  bot: {
    es: 'Id Bot',
    en: 'Bot Id',
    pt: 'Id Bot'
  },
  service: {
    es: 'Id Servicio',
    en: 'Service Id',
    pt: 'Id Serviço'
  },
  tsCreated: {
    es: 'Fecha de Creación',
    en: 'Creation Date',
    pt: 'Data de Criação'
  },
  ts: {
    es: 'Fecha',
    en: 'Date',
    pt: 'Data'
  },
  version: {
    es: 'Id Versión',
    en: 'Version Id',
    pt: 'Id Versão'
  },
  staging: {
    es: 'Staging',
    en: 'Staging',
    pt: 'Staging'
  }
}

export const BUSINESS_HOURS = {
  start: '09:00',
  end: '18:00'
}

export const CHAT_USER_STATUS ={
  online: 'online',
  idle: 'idle',
  offline: 'offline'
}

export const PROFILES_USER_CREATION = {
  [USER_ROLES.SUPER_ADMIN]: [
    USER_ROLES.EDITOR,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.AGENT,
    USER_ROLES.ROLES_MANAGER,
    USER_ROLES.GUEST
  ],
  [USER_ROLES.ROLES_MANAGER]: [
    USER_ROLES.EDITOR,
    USER_ROLES.SUPERVISOR,
    USER_ROLES.AGENT,
    USER_ROLES.GUEST
  ],
  [USER_ROLES.SYS_ADMIN]: [],
  [USER_ROLES.GUEST]: [],
  [USER_ROLES.EDITOR]: [],
  [USER_ROLES.SUPERVISOR]: [],
  [USER_ROLES.AGENT]: []
}

export const ANSWER_SEARCH_SORT_OPTIONS = [{
  label: 'newest',
  query: {
    field: '_updatedAt',
    value: -1
  }
},
{
  label: 'oldest',
  query: {
    field: '_updatedAt',
    value: 1
  }
},
{
  label: 'ascending',
  query: {
    field: 'name',
    value: 1
  }
},
{
  label: 'descending',
  query: {
    field: 'name',
    value: -1
  }
}]

export default {
  PLATFORMS,
  USERS_PLATFORMS,
  MEDIA_TYPES,
  // conditionals
  TYPES_SYMBOLS,
  CV_TYPES_SYMBOLS,
  CV_UNARY_FXS,
  CV_FXS,
  CV_TYPES_OPS,
  CV_OPTIONS,
  OPS_OPTIONS,
  CV_COMP_TYPES,
  // MEDIA FILES
  FILE_FORMATS_MAP,
  MAX_FILE_SIZE,
  CHAT_STATUS,
  CHAT_EXPORT_HEADERS,
  BUSINESS_HOURS,
  CHAT_USER_STATUS,
}
