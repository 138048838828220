export default {
  addCondition: {
    title: {
      es: 'Crear condición',
      en: 'Create condition',
      pt: 'Criar condição'
    },
    error: {
      duplicate: {
        es: 'Ya existe una condición con ese nombre.',
        en: 'A condition with that name already exists.',
        pt: 'Já existe uma condição com esse nome.'
      },
      noCondition: {
        es: 'Agrega al menos una condición a este grupo',
        en: 'Add at least one condition to this group',
        pt: 'Adicione pelo menos uma condição a este grupo'
      }
    }
  },
  table:{
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    description: {
      es: 'Descripción',
      en: 'Description',
      pt: 'Descrição',
    },

  },
  title: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Tag',
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome',
  },
  description: {
    es: 'Descripción',
    en: 'Description',
    pt: 'Descrição',
  },
  validationErrors: {
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre de la condición es requerido y debe contener menos de 256 caracteres.',
        en: 'The condition name is required and must contain less than 256 characters.',
        pt: 'O nome da condição é obrigatório e deve conter menos de 256 caracteres.',
      },
    },
    validCondition: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Agrega al menos una condición a cada grupo',
        en: 'Add at least one condition to each group',
        pt: 'Adicione pelo menos uma condição a cada grupo',
      },
    },
    duplicatedName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La condición contiene un nombre duplicado.  ',
        en: 'The condition contains a duplicate name. ',
        pt: 'A condição contém um nome duplicado. ',
      },
    }
  },
  popup: {
    titleAdd: {
      es: 'Crear condición',
      en: 'Create condition',
      pt: 'Criar condição',
    },
    titleEdit: {
      es: 'Editar condición',
      en: 'Edit condition',
      pt: 'Editar condição',
    },
    titleReadOnly: {
      es: 'Ver Condición (solo lectura)',
      en: 'View Condition (read-only)',
      pt: 'Ver Condição (somente leitura)',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    placeholder:{
      name:{
        es:'Escriba un nombre de condición',
        en:'Write a condition name',
        pt:'Escreva um nome de condição',
      },
      description:{
        es:'Agregue una descripción para comprender mejor de qué se trata la condición.',
        en:'Add a description to understand better what the condition is about',
        pt:'Adicione uma descrição para entender melhor do que se trata a condição',
      }
    },
    description: {
      es: 'Descripción',
      en: 'Description',
      pt: 'Descrição'
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    editText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    createText: {
      es: 'Crear',
      en: 'Create',
      pt: 'Criar',
    },

    successEdit: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La condición fue actualizada correctamente.',
        en: 'The condition was successfully updated.',
        pt: 'A condição foi atualizada com sucesso.',
      },
    },
    successCreate: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La condición fue creada correctamente. ',
        en: 'The condition was created successfully.',
        pt: 'A condição foi criada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
      regex: {
        es: 'Regex invalida',
        en: 'Invalid regex',
        pt: 'Regex invalida',
      }
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está a punto de borrar una condición, esta acción es irreversible. ¿Estas seguro? ',
        en: 'You are about to delete a condition, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a excluir uma condição, esta ação é irreversível. Tem certeza?',
      },
    },
    dialogReference: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está condición esta siendo usada, no es posible eliminar. ',
        en: 'This condition is being used, it is not possible to delete it.',
        pt: 'Esta condição está sendo utilizada, não é possível excluí-la.',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La condición se eliminó correctamente.',
        en: 'The condition was removed successfully.',
        pt: 'A condição foi removida com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
}