import get from 'lodash/get'

import ChartBuilderService from '../../services/chartBuilder.service.js'

export default {
  async GET_CHARTS_BUILDER({ commit }) {
    try {
      const response = await ChartBuilderService.getAll()
      commit('SET_CHARTS_BUILDER', response.data.result);
      commit('SET_CHARTS_LENGTH', response.data.length);
      return response.data;
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async CREATE_CHART_BUILDER({ commit }, data) {
    try {
      const response = await ChartBuilderService.createOne(data)
      commit('ADD_CHART_BUILDER', response.data);
      commit('ADD_CHART_LENGTH');
      return response.data;
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async DELETE_CHART_BUILDER({ commit }, _id) {
    try {
      const response = await ChartBuilderService.deleteOne(_id)
      commit('DELETE_CHART_BUILDER', _id);
      commit('SUBTRACT_CHART_LENGTH');
      return response.data;
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async GET_CHART_BUILDER(data, _id) {
    try {
      const response = await ChartBuilderService.getOne(_id)
      const chartsEmbedded = get(response.data, 'chartsEmbedded', []).map((data) => ({ ...data, query: JSON.parse(data.query)}))
      return { ...response.data, query: JSON.parse(response.data.query), chartsEmbedded } ;
    } catch (error) {
      return await Promise.reject(error)
    }
  },
  async SET_TABLE_SHOW_ACTION({ commit }, data) {
    commit('SET_TABLE_SHOW', data);
  },
  async SET_TABLE_SHOW_BODY_ACTION({ commit }, data) {
    commit('SET_TABLE_SHOW_BODY', data);
  }
};
