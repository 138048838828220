export default {
  title: {
    es: 'Recupera tu contraseña',
    en: 'Recover your password',
    pt: 'Recupere sua senha',
  },
  description: {
    es: 'Ingrese su dirección de correo electrónico y le enviaremos instrucciones sobre cómo restablecer su contraseña.',
    en: "Please enter your email address and we'll send you instructions on how to reset your password.",
    pt: 'Por favor, digite seu endereço de e-mail e enviaremos instruções sobre como reiniciar sua senha.',
  },
  form: {
    email: {
      es: 'Correo electrónico',
      en: 'Email',
      pt: 'E-mail',
    },
    error: {
      invalidEmail: {
        es: 'La dirección de correo electrónico ingresada es inválida.',
        en: 'The email address you entered is invalid.',
        pt: 'O endereço de e-mail que você digitou é inválido.',
      },
    },
    response: {
      success: {
        title: {
          es: 'Éxito',
          en: 'Success',
          pt: 'Sucesso',
        },
        description: {
          es: 'Si la dirección de correo electrónico que ingresaste es válida recibirás un correo electrónico con los pasos a seguir.',
          en: 'If the email address you entered is correct you will receive an email with the steps to follow.',
          pt: 'Se o endereço de e-mail que você inscreveu estiver correto, você receberá um e-mail com os passos a seguir.',
        },
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro',
        },
        description: {
          es: 'Parece que ocurrió un error.',
          en: 'Looks like something went wrong.',
          pt: 'Parece que algo deu errado.',
        },
      },
    },
  },
  backToLogin: {
    es: 'Volver',
    en: 'Back To Login',
    pt: 'Voltar ao Login',
  },
  recoverPassword: {
    es: 'Recuperar contraseña',
    en: 'Recover Password',
    pt: 'Recuperar senha',
  },
  toBeImplement: {
    title: {
      es: '¡Próximamente!',
      en: 'Coming soon!',
      pt: 'Em breve!',
    },
    text: {
      es: '¡Esta funcionalidad aún no está implementada!',
      en: 'This feature is not yet implemented!',
      pt: 'Esta funcionalidade ainda não foi implementado!',
    },
  },
}