import { Response } from '../Response'

export class TextMessageResponse extends Response {
  constructor(text, platforms = ['all']) {
    super()
    this.text = text
    this.platforms = platforms
  }

  getResponseToSave() {
    return {
      platforms: this.platforms,
      responseText: this.text
    }
  }

  isImageMessage() {
    return false
  }

  isMediaMessage() {
    return false
  }

  isTextMessage() {
    return true
  }
}
