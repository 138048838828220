export default {
  SET_CONDITIONS_CAT(state, conditionsCAT) {
    state.conditionsCAT = conditionsCAT
  },
  SET_OPEN_POPUP(state, openCruPopup) {
    state.openCruPopup = openCruPopup
  },
  SET_CONDITIONS_CAT_LENGTH(state, conditionsCATLength) {
    state.conditionsCATLength = conditionsCATLength
  },
  UNSHIFT_CONDITIONS_CAT(state, conditionCAT) {
    state.conditionsCAT = [conditionCAT].concat(state.conditionsCAT)
  },
  SPLICE_CONDITIONS_CAT(state, _id) {
    const index = state.conditionsCAT.findIndex(conditionsCAT => {
      return conditionsCAT._id === _id;
    })
    state.conditionsCAT.splice(index, 1)
  },
};