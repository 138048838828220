export default {
  charts: state => {
    return state.charts
  },
  chartShowTable: state => {
    return state.chartShowTable
  },
  chartShowTableBody: state => {
    return state.chartShowTableBody
  }
};
