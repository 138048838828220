import state from '../store/state'
import Request from './request'

const PATH = {
  GET_ALL: '/chart-builder-grouped',
}


const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getOne = (_id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.GET_ALL}/${_id}`,
    config
  )
}

const getAll = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.GET_ALL}`,
    config
  )
}

export default {
  getOne,
  getAll,
}