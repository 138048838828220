import Request from './request'
import state from '../store/state'

const PATHS = {
  GET_ALL: '/available-applications',
  INSTALLED_APPS: '/marketplace-applications'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getAvailableApps = async ({ version, service }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    `${state.config.konecta}${PATHS.GET_ALL}?where[version]=${version}&where[service]=${service}`,
    config
  )
}

const _installApp = async data => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(
    `${state.config.konecta}${PATHS.INSTALLED_APPS}`,
    data,
    config
  )
}

/**
 * Get all installed apps of a bot
 *
 * @param {object} data contains the service and version of the bot where the app will be installed
 * @returns {Promise}
 */

const _getInstalledApps = async ({ service, version }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATHS.INSTALLED_APPS}?where[service]=${service}&where[version]=${version}`,
    config
  )
}

const _getInstalledAppsForResponse = async ({ service, version, name , enabled}) => {
  const token = _getToken()

  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.get(
    `${state.config.konecta}${
      PATHS.INSTALLED_APPS
    }?where[service]=${service}&where[version]=${version}${enabled !== undefined && enabled !== null ? `&where[enabled]=${enabled}` : ''}${
      name ? `&where[appName]=${name}` : ''
    }`,
    config
  )
}

const _getDataFromApp = async (data) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.post(`${state.config.konecta}${PATHS.INSTALLED_APPS}/data`, data, config)
}

const _updateFields = async ({ service, version, name, ...data }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATHS.INSTALLED_APPS}?where[service]=${service}&where[version]=${version}&where[appName]=${name}`,
    data,
    config
  )
}

export default {
  installApp: _installApp,
  updateFields: _updateFields,
  getDataFromApp: _getDataFromApp,
  getInstalledApps: _getInstalledApps,
  getAvailableApps: _getAvailableApps,
  getInstalledAppsForResponse: _getInstalledAppsForResponse,
}
