export default {
  errors: {
    PasswordHistoryConflict: {
      es: 'La contraseña no puede ser idéntica a las últimas ({n}) contraseñas',
      en: 'Password can not be identical to the last ({n}) passwords',
      pt: 'A senha não pode ser idêntica às últimas ({n}) senhas'
    },
    PasswordCriteriaConflict: {
      es: 'La contraseña debe cumplir con los criterios.',
      en: 'The password must meet the criteria.',
      pt: 'A senha deve atender aos critérios.'
    },
    PasswordLengthError: {
      es: 'Mínimo de <%=minLength%> caracteres de longitud',
      en: 'Minimum of <%=minLength%> characters in length',
      pt: 'Mínimo de <%=minLength%> caracteres de comprimento'
    },
    PasswordComplexityError: {
      es: 'Incluir al menos un número',
      en: 'Include at least one number',
      pt: 'Incluir pelo menos um número'
    },
    PasswordMaxLengthError: {
      es: 'Máximo de 64 caracteres de longitud',
      en: 'Maximum of 64 characters in length',
      pt: 'Máximo de 64 caracteres de comprimento'
  }  
  },
  validations: {
    title: {
      es: 'La contraseña debe cumplir con lo siguiente:',
      en: 'The password must meet the following criteria:',
      pt: 'A senha deve atender ao seguinte:'  
    },
    text: {
      minLength: {
        es: 'Mínimo de <%=minLength%> caracteres de longitud',
        en: 'Minimum of <%=minLength%> characters in length',
        pt: 'Mínimo de <%=minLength%> caracteres de comprimento'
      },
      maxLength: {
        es: 'Máximo de <%=maxLength%>caracteres de longitud',
        en: 'Maximum of <%=maxLength%> characters in length',
        pt: 'Máximo de <%=maxLength%> caracteres de comprimento'
      },
      hasNumber: {
        es: 'Incluir al menos un número',
        en: 'Include at least one number',
        pt: 'Incluir pelo menos um número'
      },
      historyPass: {
        es: 'No ser idéntica a las últimas [<%=n%>] contraseñas',
        en: 'Not be identical to the last [<%=n%>] passwords',
        pt: 'Não ser idêntica às últimas [<%=n%>] senhas'
      }
    }
  },

 
}