export default {
  generalSettings: {
    title: {
      es: 'Configuración general',
      en: 'General settings',
      pt: 'Configurações gerais'
    },
    passwordExpiration: {
      es: 'Expiración de contraseña',
      en: 'Password expiration',
      pt: 'Vencimento da senha'
    },
    passwordExpirationOptions_0: [
      {
        milliseconds: 0,
        es: 'Nunca',
        en: 'Never',
        pt: 'Nunca'
      },
      {
        milliseconds: 600000
      }
    ],
    passwordExpirationOptions_1: [
      {
        milliseconds: 604800000
      },
      {
        milliseconds: 1209600000
      },
      {
        milliseconds: 1814400000
      },
      {
        milliseconds: 2592000000
      }
    ],
    passwordExpirationOptions_2: [
      {
        milliseconds: 5184000000
      },
      {
        milliseconds: 7776000000
      },
      {
        milliseconds: 15552000000
      },
      {
        milliseconds: 31536000000
      }
    ],
    save: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar'
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar'
    },
    notify: {
      success: {
        title: {
          es: 'Éxito!',
          en: 'Success!',
          pt: 'Sucesso!'
        },
        text: {
          es: 'Configuración actualizada exitosamente.',
          en: 'Setting updated successfully.',
          pt: 'Configuração atualizada com sucesso.'
        }
      },
      error: {
        title: {
          es: 'Error',
          en: 'Error',
          pt: 'Erro'
        },
        text: {
          es:
            'Error actualizando configuración. Por favor, intente nuevamente.',
          en: 'Error updating setting. Please, try again.',
          pt: 'Erro em atualizar a configuração. Por favor, tente novamente.'
        }
      }
    }
  },
  manageUsers: {
    title: {
      es: 'Usuarios',
      en: 'Users',
      pt: 'Usuários'
    },
    deleteUserPrompt: {
      title: {
        es: 'Borrar usuario',
        en: 'Delete user',
        pt: 'Apagar usuário'
      },
      content: {
        es:
          '¿Estás seguro que quieres borrar el usuario? Esta acción es irreversible',
        en:
          'Are you sure you want to delete the user? This action is irreversible.',
        pt: 'Tem certeza que quer deletar o usuário? Esta ação é irreversível.'
      }
    },
    deleteSuccess: {
      title: {
        es: 'Éxito',
        en: 'Sucess',
        pt: 'Sucesso'
      },
      content: {
        es: 'Usuario borrado con éxito',
        en: 'User deleted successfully',
        pt: 'Usuário excluído com sucesso'
      }
    },
    blockSuccess: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso'
      },
      content: {
        es: 'Usuario bloqueado exitosamente',
        en: 'User was blocked successfully',
        pt: 'Usuário bloqueado com sucesso'
      }
    },
    unBlockSuccess: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso'
      },
      content: {
        es: 'Usuario desbloqueado exitosamente',
        en: 'User was unblocked successfully',
        pt: 'Usuário desbloqueado com sucesso'
      }
    },
    empty: {
      title: {
        es: 'Todavía no hay usuarios',
        en: 'There are no users yet',
        pt: 'Não há usuários ainda'
      },
      addUsers: {
        es: 'Crear usuario',
        en: 'Create a user',
        pt: 'Criar usuário'
      }
    }
  },
  createUser: {
    title: {
      es: 'Crear usuario',
      en: 'Create a user',
      pt: 'Criar usuário'
    },
    btn: {
      create: {
        es: 'Crear usuario',
        en: 'Create user',
        pt: 'Criar usuário'
      },
      update: {
        es: 'Guardar cambios',
        en: 'Save changes',
        pt: 'Salvar as mudanças'
      },
      cancel: {
        es: 'Cancelar',
        en: 'Cancel',
        pt: 'Cancelar'
      }
    },
    form: {
      error: {
        generic: {
          title: {
            es: 'El formulario tiene errores',
            en: 'The form has errors',
            pt: 'O formulário tem erros'
          }
        },
        name: {
          es: 'Por favor ingrese un nombre',
          en: 'Please enter a name',
          pt: 'Por favor, digite um nome'
        },
        username: {
          es: 'Por favor ingrese un nombre de usuario',
          en: 'Please enter a username',
          pt: 'Por favor, digite um nome de usuário'
        },
        email: {
          es: 'Por favor ingrese una dirección de correo electrónico válida',
          en: 'Please enter a valid email address',
          pt: 'Por favor, digite um endereço de e-mail válido'
        },
        password: {
          es: 'Por favor ingrese una contraseña',
          en: 'Please enter a password',
          pt: 'Por favor, digite uma senha'
        },
        roles: {
          es: 'Por favor selecciona al menos un rol',
          en: 'Please select at least one role',
          pt: 'Por favor, selecione pelo menos um papel'
        },
        emailAlreadyInUse: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!'
          },
          text: {
            es: 'Correo electrónico ya en uso.',
            en: 'Email already in use.',
            pt: 'Email já em uso.'
          }
        }
      }
    }
  },
  editUser: {
    title: {
      es: 'Editar Usuario',
      en: 'Edit User',
      pt: 'Editar Usuário'
    },
    changePassword: {
      es: 'Cambiar contraseña',
      en: 'Change password',
      pt: 'Mudar a senha'
    },
    newPassword: {
      es: 'Nueva contraseña',
      en: 'New password',
      pt: 'Nova senha'
    },
    repeatPassword: {
      es: 'Repetir contraseña',
      en: 'Repeat password',
      pt: 'Repita a senha'
    },
    passwordExpiration: {
      title: {
        es: 'Expiración de contraseña',
        en: 'Password expiration',
        pt: 'Vencimento da senha'
      },
      button: {
        es: 'Expirar ahora',
        en: 'Expire now',
        pt: 'Expira agora'
      },
      never: {
        es: 'Nunca',
        en: 'Never',
        pt: 'Nunca'
      }
    }
  },
  roles: {
    multiselectPlaceholder: {
      es: 'Seleccionar roles',
      en: 'Select roles',
      pt: 'Seleccionar roles'
    },
    supervisor: [
      {
        es: 'Puede acceder a Análisis',
        en: 'Can access to Analytics',
        pt: 'Pode acessar Análises'
      },
      {
        es: 'Puede acceder a Retroalimentación',
        en: 'Can access to Feedback',
        pt: 'Pode acessar Feedback'
      },
      {
        es: 'Puede editar las horas de disponibilidad del Agente y las colas de Atención',
        en: 'Can edit Agent availability hours and Attention queues',
        pt: 'Pode editar as horas de disponibilidade do Agente e as filas de Atendimento'
      }
    ],    
    editor: [
      {
        es: 'Puede crear y editar bots',
        en: 'Can create and edit bots',
        pt: 'Pode criar e editar bots'
      }
    ],
    agent: [
      {
        es: 'Puede tomar el control de conversaciones',
        en: 'Can take control of conversations',
        pt: 'Pode assumir o controle das conversas'
      }
    ],
    rolesManager: [
      {
        es: 'Puede crear usuarios con roles de Agente, Supervisor de Chat, Editor de Bots e Invitado',
        en: 'Can create users with role Agent, Chat supervisor, Bot Editor and Guest',
        pt: 'Pode criar usuários com papéis de Agente, Supervisor de Chat, Editor de Bots e Convidado'
      }
      
    ],
    guest: [
      {
        es: 'Puede ver contenido con acceso de solo lectura en: Todos los chats, Análisis, Bots y Mejoras',
        en: 'Can view content with read-only access across: All chats, Analytics, Bots, and Improve',
        pt: 'Pode visualizar conteúdos com acesso somente leitura em: Todos os chats, Análises, Bots e Melhorias'
      }
    ]
    
  }
}
