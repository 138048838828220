import Request from './request'
import state from '../store/state'

const PATH = {
  CREDENTIAL: '/credential'
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getNLU = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    state.config.konecta +
      PATH.CREDENTIAL +
      '?where[type][$in]=luis&where[type][$in]=watson',
    config
  )
}

const _createCredential = credential => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    state.config.konecta + PATH.CREDENTIAL,
    credential,
    config
  )
}

const _updateCredential = (_id, credential) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    state.config.konecta + PATH.CREDENTIAL + '/' + _id,
    credential,
    config
  )
}

const _deleteCredential = _id => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    state.config.konecta + PATH.CREDENTIAL + '/' + _id,
    config
  )
}

export default {
  getNLU: _getNLU,
  createCredential: _createCredential,
  updateCredential: _updateCredential,
  deleteCredential: _deleteCredential
}
