import ClustersService from '@/services/clusters.services'

/** @type {import('vuex').Module} */
export default {
  namespaced: true,
  state: {
    clusters: [],
    centers: {},
  },
  getters: {
    clusters: state => {
      return state.clusters
    },
    centers: state => clusterId => {
      return state.centers[clusterId]
    },
  },
  mutations: {
    SET_CLUSTERS: (state, clusters) => {
      state.clusters = clusters
    },
    SET_CENTERS: (state, payload) => {
      const clusterId = payload.clusterId
      const centers = payload.centers
  
      state.centers[clusterId] = centers
    },
  },
  actions: {
    getClusters({ commit }) {
      return ClustersService.getClusters().then(response => {
        commit('SET_CLUSTERS', response.data)
      })
    },
    async geClustersPagination({ commit }, queryParams) {
      try {
        const response = await ClustersService.getClusters(queryParams)
        commit('SET_CLUSTERS', response.data.result);
        return response.data;
      } catch (error) {
        console.error(error)
        throw error
      }
    },
    getCenters({ commit }, clusterId) {
      return ClustersService.getCenters(clusterId).then(response => {
        commit('SET_CENTERS', { clusterId, centers: response.data })
      })
    },
    createCluster(context, payload) {
      return ClustersService.createCluster(payload)
    },
    setTrain(context, payload) {
      return ClustersService.setTrain(payload)
    }
  }
}
