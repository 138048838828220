import ChartBuilderGroupedService from '../../services/chartBuilderGrouped.service.js'

export default {
  async GET_CHART_BUILDER_GROUPED(data, _id) {
    try {
      const response = await ChartBuilderGroupedService.getOne(_id)
      return {
        ...response.data,
        layout: response.data.layout.map((layout) => ({
          ...layout,
          ...(layout.chart ? {
            chart: {
              ...layout.chart,
            query: JSON.parse(layout.chart.query)
            }
          } : {})
        }))
      }
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
};
