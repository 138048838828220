import templateBubblesService from '@/services/templateBubbles.service';

export default {
  TEMPLATE_BUBBLES_GET_ALL: ({ commit }, companyId) => {
    return templateBubblesService
      .getAll(companyId)
      .then((resp) => {
        commit('templateBubblesSet', resp.data.result);
        return resp;
      })
      .catch(() => []);
  }
};
