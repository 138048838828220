export default {
  removePartOfCondition: {
    title: {
      es: 'Confirmación',
      en: 'Confirmation',
      pt: 'Confirmação',
    },
    text: {
      es: '¿Estás seguro de querer eliminar esta parte de condición?',
      en: 'Are you sure you want to delete this part of condition?',
      pt: 'Tem certeza que quer apagar esta parte da condição?'
    },
    acceptText: {
      es: 'Sí, eliminar esta parte de condición',
      en: 'Yes, delete this part of condition',
      pt: 'Sim, excluir esta parte da condição'
    },
  },
  funcs: {
    true: {
      es: 'Siempre',
      en: 'Always',
      pt: 'Sempre',
    },
    false: {
      es: 'Nunca',
      en: 'Never',
      pt: 'Nunca',
    },
    exists: {
      es: 'Existe',
      en: 'Exists',
      pt: 'Existe',
    },
    notExists: {
      es: 'No existe',
      en: 'Not exists',
      pt: 'Não existe',
    },
    equal: {
      es: 'Igual',
      en: 'Equals',
      pt: 'Igual',
    },
    notEqual: {
      es: 'No es igual',
      en: 'Not equals',
      pt: 'Não é igual',
    },
    lessThan: {
      es: 'Menor que',
      en: 'Less than',
      pt: 'Menor que',
    },
    lessThanOrEqual: {
      es: 'Menor o igual',
      en: 'Less than or equal',
      pt: 'Menor ou igual',
    },
    listIsEmpty: {
      es: 'Lista vacía',
      en: 'Empty list',
      pt: 'Lista vazia',
    },
    listIsNotEmpty: {
      es: 'Lista no vacía',
      en: 'List not empty',
      pt: 'Lista não vazia',
    },
    greaterThan: {
      es: 'Mayor que',
      en: 'Greater than',
      pt: 'Maior que',
    },
    greaterThanOrEqual: {
      es: 'Mayor o igual',
      en: 'Greater than or equal',
      pt: 'Maior ou igual',
    },
    contains: {
      es: 'Contiene',
      en: 'Contains',
      pt: 'Contém',
    },
    startWith: {
      es: 'Comience con',
      en: 'Start with',
      pt: 'Comece com',
    },
    containsNumber: {
      es: 'Contiene número',
      en: 'Contains number',
      pt: 'Contém número',
    },
    containsIndex: {
      es: 'Contiene índice',
      en: 'Contains index',
      pt: 'Contém índice',
    } ,
    schedule: {
      es: 'Dentro de calendario',
      en: 'In schedule',
      pt: 'Dentro de cronograma',
    },
    scheduleOut: {
      es: 'Fuera de calendario',
      en: 'Out of schedule',
      pt: 'Fuera de cronograma',
    },
    match: {
      es: 'RegEx',
      en: 'RegEx',
      pt: 'RegEx',
    }
  },
  'ctx&ctx': {
    es: 'Variable',
    en: 'Variable',
    pt: 'Variável',
  },
  'ctx&var': {
    es: 'Valor',
    en: 'Value',
    pt: 'Valor',
  },
  'ctx&url': {
    es: 'url',
    en: 'url',
    pt: 'url',
  },
  'ctx&input': {
    es: 'Captura',
    en: 'Capture',
    pt: 'Captura',
  },
  'User-input': {
    es: 'Entrada de usuario',
    en: 'User input',
    pt: 'Entrada do usuário',
  },
  'url-proactive': {
    es: 'URL',
    en: 'URL',
    pt: 'URL',
  },
  'field-key': {
    es: 'Clave de campo',
    en: 'Field key',
    pt: 'Chave de campo',
  },
  
  addBranch: {
    es: 'Agregar grupo',
    en: 'Add group',
    pt: 'Adicionar grupo',
  },
  addLeaf: {
    es: 'Agregar condición',
    en: 'Add condition',
    pt: 'Adicionar condição',
  },
  unsavedChanges: {
    es: 'Completa el grupo para que la condición se pueda guardar',
    en: 'Complete the group so that the entire condition can be saved',
    pt: 'Complete o grupo para que a condição possa ser salva'
  },
  answerContent: {
    es: 'Contenido de la respuesta',
    en: 'Answer content',
    pt: 'Conteúdo da resposta',
  },
  answerContentPreview: {
    es: 'Vista previa del contenido de la respuesta',
    en: 'Answer content preview',
    pt: 'Visualização do conteúdo da resposta',
  },
  answerContentPreviewIsEmpty: {
    es: 'Esta respuesta no tiene contenido',
    en: 'This answer has no content',
    pt: 'Esta resposta não tem conteúdo',
  },
  ifTitleAll: {
    es: 'las siguientes condiciones se cumplen',
    en: 'the following conditions are met',
    pt: 'as seguintes condições são atendidas',
  },
  ifTitleAny: {
    es: 'de las siguientes condiciones se cumple',
    en: 'of the following conditions are met',
    pt: 'das seguintes condições for atendida',
  },
  emptyBranch: {
    es: 'Agrega al menos una condición a este grupo',
    en: 'Add at least one condition to this group',
    pt: 'Adicione pelo menos uma condição a este grupo'
  },
  title: {
    es: 'Condiciones',
    en: 'Conditions',
    pt: 'Condições'
  },
  notFound: {
    es: 'Condición no encontrado',
    en: 'Condition not found',
    pt: 'Condição não encontrada'
  },
  new: {
    es: 'Condición',
    en: 'Condition',
    pt: 'Condição'
  },
  replyOutsideBusinessHours: {
    es: 'Responder fuera del horario de atención',
    en: 'Reply outside of business hours',
    pt: 'Responda fora do horário de atendimento'
  },
  name: {
    es: 'Nombre de condición (opcional)',
    en: 'Condition name (optional)',
    pt: 'Nome da condição (opcional)'
  },
  tags: {
    es: 'Etiquetas',
    en: 'Tags',
    pt: 'Etiquetas'
  },
  themes: {
    es: 'Temas',
    en: 'Themes',
    pt: 'Temas'
  },
  placeholder: {
    es: 'Buscar o añadir etiqueta',
    en: 'Search or add tag',
    pt: 'Pesquisar ou adicionar etiqueta'
  },
  placeholderTheme: {
    es: 'Buscar o añadir tema',
    en: 'Search or add theme',
    pt: 'Pesquisar ou adicionar tema'
  },
  tagPlaceholder: {
    es: 'Añadir nueva etiqueta',
    en: 'Add new tag',
    pt: 'Adicione uma nova etiqueta'
  },
  themePlaceholder: {
    es: 'Añadir nueva tema',
    en: 'Add new theme',
    pt: 'Adicione um novo tema'
  },
  noOptions: {
    es: 'Aún no se crearon etiquetas.',
    en: 'No tags created yet.',
    pt: 'Nenhuma etiqueta criada ainda.'
  },
  noThemesOptions: {
    es: 'Aún no se crearon temas.',
    en: 'No themes created yet.',
    pt: 'Nenhum tema criado ainda.'
  },
  noTag: {
    es:
      'El campo Etiquetas es requerido. En caso de no seleccionar ninguna, se asignará por defecto la etiqueta',
    en:
      'The Tags field is required. If you do not select anyone, the default tag will be',
    pt:
      'O campo Tags é obrigatório. Se você não selecionar nenhum, o tag será atribuído por padrão'
  },
  noTheme: {
    es:
      'El campo Temas es requerido. En caso de no seleccionar ninguna, se asignará por defecto el tema',
    en:
      'The Themes field is required. If you do not select anyone, the default theme will be',
    pt:
      'O campo Temas é obrigatório. Se você não selecionar nenhum, o tema será atribuído por padrão'
  },
  unclassified: {
    es: 'Sin clasificar',
    en: 'Unclassified',
    pt: 'Não classificado'
  },
  options: {
    intentions: {
      es: '# Intenciones',
      en: '# Intents',
      pt: 'Intenções'
    },
    entities: {
      es: '@ Entidades',
      en: '@ Entities',
      pt: '@ Entidades'
    },
    regex: {
      es: '(.*) RegEx',
      en: '(.*) RegEx',
      pt: '(.*) RegEx'
    },
    variables: {
      es: 'Variables',
      en: 'Variables',
      pt: 'Variáveis'
    }
  },
  types: {
    intent: {
      es: 'Intención',
      en: 'Intent',
      pt: 'Intenção'
    },
    entity: {
      es: 'Entidad',
      en: 'Entity',
      pt: 'Entidade'
    },
    match: {
      es: 'RegEx',
      en: 'RegEx',
      pt: 'RegEx'
    },
    type: {
      es: 'Función',
      en: 'Function',
      pt: 'Função'
    }
  },
  value: {
    es: 'Valor',
    en: 'Value',
    pt: 'Valor'
  },
  type: {
    es: 'Tipo',
    en: 'Type',
    pt: 'Tipo'
  },
  operator: {
    es: 'Operador',
    en: 'Operator',
    pt: 'Operador'
  },
  dividerText: {
    empty: {
      es: 'Aún no se agregaron condiciones',
      en: 'There are no conditions yet',
      pt: 'Não há condições ainda'
    },
    single: {
      es: 'condición agregada',
      en: 'condition added',
      pt: 'condição adicionada'
    },
    plural: {
      es: 'condiciones agregadas',
      en: 'conditions added',
      pt: 'condições adicionadas'
    }
  },
  buttons: {
    add: {
      es: 'Agregar condición',
      en: 'Add condition',
      pt: 'Adicionar condição'
    },
    remove: {
      es: 'Quitar condición',
      en: 'Remove condition',
      pt: 'Remover condição'
    }
  },
  messages: {
    error: {
      addCondition: {
        title: {
          es: 'Tipo existente',
          en: 'Existing type',
          pt: 'Tipo existente'
        },
        text: {
          es: 'Ya existe un condicional de este tipo',
          en: 'A conditional of this type already exists',
          pt: 'Um condicional desse tipo já existe'
        }
      },
      emptyCustomValue: {
        title: {
          es: 'Ingrese valor',
          en: 'Add value',
          pt: 'Adicione valor'
        },
        text: {
          es: 'Ingrese el valor de la condición',
          en: 'Add condition value',
          pt: 'Adicione o valor da condição'
        }
      }
    }
  },
  search: {
    placeholder: {
      es: 'Agregar una condición',
      en: 'Add condition',
      pt: 'Adicione condição'
    },
    noResult: {
      es: 'No se encontraron resultados para ',
      en: 'No results found for ',
      pt: 'Não foram encontrados resultados para '
    },
    newIntent: {
      es: 'Nueva intención',
      en: 'New intent',
      pt: 'Nova intenção'
    },
    newEntity: {
      es: 'Nueva entidad',
      en: 'New entity',
      pt: 'Nova entidade'
    }
  },
  entities: {
    placeholder: {
      es: 'Seleccione valor de entidad',
      en: 'Select entity value',
      pt: 'Selecione o valor da entidade'
    }
  },
  variables: {
    title: {
      es: 'Variable',
      en: 'Variable',
      pt: 'Variável'
    },
    placeholder: {
      es: 'Seleccione la variable',
      en: 'Select variable',
      pt: 'Selecione o variável'
    },
    action:{
      title: {
        es: 'Acción',
        en: 'Action',
        pt: 'Ação'
      },
      placeholder: {
      es: 'Seleccione la acción',
      en: 'Select action',
      pt: 'Selecione a ação'
      }
    },
    value:{
      title: {
        es: 'Valor',
        en: 'Value',
        pt: 'Valor'
      },
      placeholder: {
      es: 'Escriba un valor',
      en: 'Write a value',
      pt: 'Escreva um valor'
      }
    },
    expression: {
      title: {
        es: 'Expresión',
        en: 'Expression',
        pt: 'Expressão'
      },
      placeholder: {
        es: 'Escriba una expresión aritmética...',
        en: 'Write an arithmetic expression...',
        pt: 'Escrever uma expressão aritmética...'
      },
      valid: {
        es: 'Expresión válida',
        en: 'Valid expression',
        pt: 'Expressão válida'
      },
      invalid: {
        es: 'Expresión inválida',
        en: 'Invalid expression',
        pt: 'Expressão inválida'
      }
    },
    typePlaceholder: {
      es: 'Objetivo',
      en: 'Target',
      pt: 'Objetivo'
    }
  },
  nodeType: {
    es: 'Tipo de nodo',
    en: 'Node type',
    pt: 'Tipo de nó',
    leaf: {
      es: 'Hoja',
      en: 'Leaf',
      pt: 'Folha'
    },
    branch: {
      es: 'Rama',
      en: 'Branch',
      pt: 'Ramo'
    }
  },
  operators: {
    title: {
      es: 'Operación',
      en: 'Operation',
      pt: 'Operação'
    },
    placeholder: {
      es: 'Seleccione la operación',
      en: 'Select the operation',
      pt: 'Selecione a operação'
    }
  },
  tree: {
    if: {
      es: 'Si',
      en: 'If',
      pt: 'Se'
    },
    operator: {
      and: {
        es: 'todas',
        en: 'all',
        pt: 'todas'
      },
      or: {
        es: 'alguna',
        en: 'any',
        pt: 'algum'
      }
    }
  }
}
