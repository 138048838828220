import tagCategoryService from '@/services/tagCategory.service';

export default {
  TAG_CATEGORY_GET_ALL: ({ commit }, queryParams) => {
    return tagCategoryService
      .getAll(queryParams)
      .then((resp) => {
        commit('tagCategoriesSet', resp.data.result);
        return resp.data.result;
      })
      .catch(() => []);
  },
  TAG_CATEGORY_CREATE_ONE: ({ commit }, body) => {
    return tagCategoryService
      .createOne(body)
      .then((resp) => {
        commit('tagCategoriesCreate', resp.data);
        return resp;
      })
      .catch((err) => Promise.reject(err));
  },
  TAG_CATEGORY_UPDATE_ONE: ({ commit }, { _id, body }) => {
    return tagCategoryService
      .updateOne({ _id, body })
      .then((resp) => {
        commit('tagCategoryUpdate', resp.data);
        return resp;
      })
      .catch((err) => Promise.reject(err));
  },
  TAG_CATEGORY_DELETE_ONE: ({ commit }, _id) => {
    return tagCategoryService
      .deleteOne(_id)
      .then((resp) => {
        commit('tagCategoryDelete', { _id, ...resp.data });
        return resp;
      })
      .catch((err) => Promise.reject(err));
  }
};
