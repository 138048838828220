import Request from './request'
import state from '../store/state'

const PATH = {
  CLUSTER: '/cluster',
  TRAINER: '/trainer'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getClusters = (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
    params: {
      ...queryParams,
    }
  }
  return Request.get(`${state.config.konecta}${PATH.CLUSTER}`, config)
}

const _getCenters = clusterId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.CLUSTER}/${clusterId}/centers`,
    config
  )
}
const _createCluster = cluster => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${state.config.konecta}${PATH.CLUSTER}`, cluster, config)
}

const _setTrain = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const payloadData = {
    intent: payload.intent,
    versions: payload.versions
  }

  return Request.post(
    `${state.config.konecta}${PATH.TRAINER}/${payload.id}?where[staging]=false`,
    payloadData,
    config
  )
}

export default {
  getClusters: _getClusters,
  getCenters: _getCenters,
  createCluster: _createCluster,
  setTrain: _setTrain
}
