import Request from '../../services/request'
import get from 'lodash/get'
import head from 'lodash/head'

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
 const _getToken = () => {
  /** Pending */
  return 'test'
}

const PATH = {
  readWidget: '/fauna_widget',
}

export default {
  GET_FAUNA_WIDGET_BY_BOTID({ commit, rootState }, botId) {
      const token = _getToken()
      const config = {
        headers: {
          authorization: `Bearer ${token}`,
          'x-company': rootState.companyId,
          'x-key': rootState.companyAuthKey
        }
      }

      return Request.get(
        `${rootState.config.konecta}${PATH.readWidget}?where[id]=${botId}`,
        config
      ).then(resp => {
        const fauna = get(head(resp.data), 'data');
        commit('SET_FAUNA_WIDGET', fauna)
        return fauna;
      }).catch(error => error)
  }
};
