export default {
  proactiveRules(state) {
    return state.proactiveRules;
  },
  proactiveRulesLength(state) {
    return state.proactiveRulesLength;
  },
  openCruPopup: state => {
    return state.openCruPopup
  },
};