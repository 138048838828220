export default {
  bot: {
    placeholder: {
      es: 'Buscar bot',
      en: 'Search bot',
      pt: 'Pesquisar bot',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    },
  },
  user: {
    placeholder: {
      es: 'Buscar usuario',
      en: 'Search user',
      pt: 'Pesquisar user',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    },
  },
  feedback: {
    label: {
      es: 'Retroalimentación',
      en: 'Review',
      pt: 'Opinião'
    },
    like: {
      en: 'Like',
      es: 'Me gusta',
      pt: 'Eu gosto',
    },
    dislike: {
      en: 'Dislike',
      es: 'No me gusta',
      pt: 'Não gosto',
    },
    placeholder: {
      es: 'Buscar retroalimentación',
      en: 'Search review',
      pt: 'Pesquisar opinião',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    }
  },
  answer: {
    label: {
      es: 'Respuestas',
      en: 'Answers',
      pt: 'Respostas',
    },
    placeholder: {
      es: 'Buscar respuestas',
      en: 'Search answers',
      pt: 'Pesquisar respostas',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    noOptions: {
      es: 'No Option',
      en: 'No Option',
      pt: 'No Option',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    }
  },
  tag: {
    label: {
      es: 'Tags',
      en: 'Tags',
      pt: 'Tags',
    },
    placeholder: {
      es: 'Buscar Tags',
      en: 'Search Tags',
      pt: 'Pesquisar Tags',
    },
    selectedLabel: {
      es: 'Seleccionado',
      en: 'Selected',
      pt: 'Selecionado',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    noOptions: {
      es: 'No Option',
      en: 'No Option',
      pt: 'No Option',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    }
  },
  queue: {
    placeholder: {
      es: 'Buscar cola',
      en: 'Search queue',
      pt: 'Pesquisar fila',
    },
    selectedLabel: {
      es: 'Seleccionada',
      en: 'Selected',
      pt: 'Selecionada',
    },
    deselectLabel: {
      es: 'Presione enter para eliminar',
      en: 'Press enter to remove',
      pt: 'Pressione digitar para remover',
    },
    limitText: {
      pre: {
        es: 'y',
        en: 'and',
        pt: 'e',
      },
      post: {
        es: 'más',
        en: 'more',
        pt: 'mais',
      },
    },
  },
  selectC:{
    empty: {
      es: 'Seleccionar opción',
      en: 'Select option',
      pt: 'Selecione a opção',
    },
    variableEmpty: {
      es: 'Seleccionar variable',
      en: 'Select variable',
      pt: 'Selecionar variável',
    },
    criterionEmpty: {
      es: 'Seleccionar criterio',
      en: 'Select criterion',
      pt: 'Selecionar critérios',
    },
    functionEmpty: {
      es: 'Seleccionar función',
      en: 'Select function',
      pt: 'Selecione a função',
    },
    inputValueEmpty: {
      es: 'Escribe un valor',
      en: 'Write a value',
      pt: 'Escreva um valor',
    },
    selectValueEmpty: {
      es: 'Seleccionar un valor',
      en: 'Select value',
      pt: 'Selecione um valor',
    },
    any: {
      es: 'cualquiera de',
      en: 'any',
      pt: 'cualquiera',
    },
    all:{
      es: 'todos',
      en: 'all',
      pt: 'todo',
    },
    noOptionsLabel: {
      es: 'La lista está vacía.',
      en: 'List is empty.',
      pt: 'A lista está vazia.',
    },
  }
}