import timeZones from '@/constants/_TIME_ZONES.json'

/**
 * Function for generating a random ID for new messages
 * @returns String
 */
function _generateID() {
  return (
    '_' +
    Math.random()
      .toString(36)
      .substr(2, 9)
  )
}

/**
 * Set the property second level when the intent is included in the bot intents
 * @param {Array<String>} botIntents the intents that will be escalated to a second level
 * @param {Array<Object>} intentions all the intentions available in the bot
 */
const _setIntentsScalesSecondLevel = (botIntents, intentions) => {
  if (botIntents && botIntents.length > 0) {
    for (let i = 0; i < intentions.length; i++) {
      let intention = intentions[i]
      intention.derivesToAgent = botIntents.includes(
        intention.name.toLowerCase()
      )
    }
  }
}

function _browserTimeZoneIndex() {
  let index = null
  index = timeZones.findIndex(
    item =>
      item.utc &&
      item.utc.indexOf(Intl.DateTimeFormat().resolvedOptions().timeZone) > -1
  )
  if (index === null || index === -1) {
    const offset = (new Date().getTimezoneOffset() / 60) * -1
    index = timeZones.findIndex(element => element.offset === offset)
  }
  return index
}

const _getBrowserTimeZone = () => {
  const browserTZ = _browserTimeZoneIndex()
  return browserTZ > -1 ? timeZones[browserTZ] : null
}

const _replaceSpecialCharacters = str => {
  const regExpr = /[^a-zA-Z0-9-_ ]/g
  return str.replace(/ /g, '_').replace(regExpr, '')
}

const _normalizeString=string=> {
  if (typeof string !== 'string') return null
  return string
    .trim()
    .toLowerCase()
    .normalize('NFD')
    .replace(/\s\s+/g, ' ')
    .replace(/[\u0300-\u036f]/g, '')
}

export default {
  generateID: _generateID,
  setIntentsScalesSecondLevel: _setIntentsScalesSecondLevel,
  getBrowserTimeZone: _getBrowserTimeZone,
  replaceSpecialCharacters: _replaceSpecialCharacters,
  normalizeString:_normalizeString
}
