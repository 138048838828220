import { botTimeoutMessageValidationFactory} from '../factories'
const state = {
  // Bots view
  bots: [],
  filters: {
    platforms: [],
    search: '',
    categories: [],
    categoriesSelected: {}
  },
  isWidgetSettingsOpen: false,
  // Bot validations
  validations: {
    service: {
      name: false,
      description: false
    },
    nlu: {
      platform: false,
      culture: false
    },
    noIntentMessage: false,
    noIntentAction: false,
    connectionErrorMessage: false,
    intentConfidence: false,
    availability: {
      weekDaysEmpty: false,
      hoursEmpty: false,
      hoursIncorrect: false,
      messageError: false
    },
    secondLevelMessage: false,
    inactivityTime: false,
    inactivityMessage: false,
    noIntentTimesToTakeOver: false,
    platformsNoIntentTimesToTakeOver: false,
    noIntentMessage2: false,
    notDialogDetected: false,
  },

  // Select Users prompt user
  isSelectUsersPromptOpened: false,
  isSelectQueuePromptOpened: false,
  usersSelected: [],
  users: [],

  // Intents
  botTimeoutMessageValidation: botTimeoutMessageValidationFactory(),
  // Bot creation or edition
  hasTimeoutValidation: false,
  bot: {
    id: undefined,
    fork: null,
    service: {
      name: '',
      description: '',
      users: [],
      channels: []
    },
    nlu: {
      platform: 'rasa_v2',
      culture: 'es-ES'
    },
    noIntentMessage1: '',
    noIntentAction: {
      name: 'Texto',
      key: 'responseText',
      args: ['']
    },
    connectionErrorMessage: '',
    intentConfidence: 0.6,
    availability: {
      days: {
        dayFrom: 0,
        dayTo: 6,
        hourFrom: 0,
        hourTo: 23
      },
      exceptions: [],
      message: 'No trabajamos en esos dias'
    },
    intents: [],
    passiveChat: true,
    entityExtractor: false,
    useAsTemplate: false,
    disambiguationAction: {
      active: false,
      maxIntents: 3,
      message: 'Texto de desambiguacion',
      anythingElseAction: {
        active: true,
        text: 'Ninguna de las anteriores',
        actions: [
          {
            name: 'Texto',
            key: 'responseText',
            args: ['Entiendo, puedo ayudarte con algo más?']
          }
        ]
      }
    },
    notDialogDetected: {
      active: false,
      url: ''
    },
    queues: [],
    formatting: {
      date: 'MMM Do YY',
      number: {
        precision: 2
      },
    },
    resetHistoryTime: {
      hours: 0,
    },
  },
  queues: [],
  sectionReady: {
    generalSetting: false,
    usersAdministration: false,
    engineSettings: false,
    intentions: false,
    botAvailability: false
  },
  usersByChannelMap: {}
}

export default state
