// moduleBotMaker.js

/*=========================================================================================
  File Name: BotMaker.js
  Description: Bot Maker Module Store
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Kona
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
