import Request from './request'
import state from '../store/state'

const PATH = {
  IP_RANGE: '/ipRange'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getIpRanges = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.IP_RANGE}`, config)
}

const createIpRange = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(
    `${state.config.konecta}${PATH.IP_RANGE}`,
    payload,
    config
  )
}

const updateIpRange = (ipRangeId, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.IP_RANGE}/${ipRangeId}`,
    payload,
    config
  )
}

const deleteIpRange = ipRangeId => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.delete(
    `${state.config.konecta}${PATH.IP_RANGE}/${ipRangeId}`,
    config
  )
}

export default {
  getIpRanges,
  createIpRange,
  updateIpRange,
  deleteIpRange
}
