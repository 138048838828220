import { RoleCreationCapability } from './Roles/RoleCreationCapability'
import { RoleAccessCapabilities } from './Roles/RoleAccessCapabilities'
import { RoleEditionCapability } from './Roles/RoleEditionCapability'

export class Role {
  /**
   * Create a Role
   * @param roles {Array<String>}
   */
  constructor(roles) {
    this.ids = roles
    this._canCreate = new RoleCreationCapability(roles)
    this._canAccess = new RoleAccessCapabilities(roles)
    this._canEditBot = new RoleEditionCapability(roles)
  }

  get canCreate() {
    return this._canCreate
  }

  get canAccess() {
    return this._canAccess
  }

  get canEditBot() {
    return this._canEditBot
  }
}
