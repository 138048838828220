import Request from './request'
import state from '../store/state'

const PATH = {
  MESSAGES: '/message',
  TAKEOVER: '/takeover/respond-takeover'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getMessages = ({ activeChatUser, sender, fromMessage, skip, limit }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  if (fromMessage) {
    return Request.get(
      `${state.config.konecta}${PATH.MESSAGES}?where[chat]=${activeChatUser}&sender=${sender}&skip=${skip}&limit=${limit}&fromMessage=${fromMessage}`,
      config
    )
  }

  return Request.get(
    `${state.config.konecta}${PATH.MESSAGES}?where[chat]=${activeChatUser}&sender=${sender}&skip=${skip}&limit=${limit}`,
    config
  )
}

const _respondTakeover = message => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(state.config.konecta + PATH.TAKEOVER, message, config)
}

const _sendFile = (uploadEndpoint, data) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,
      'content-type': 'multipart/form-data'
    }
  }

  return Request.post(state.config.konecta + uploadEndpoint, data, config)
}

export default {
  getMessages: _getMessages,
  respondTakeover: _respondTakeover,
  sendFile: _sendFile
}
