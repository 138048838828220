export default {
  SET_PROACTIVE_RULE(state, proactiveRules) {
    state.proactiveRules = proactiveRules
  },
  SET_OPEN_POPUP(state, openCruPopup) {
    state.openCruPopup = openCruPopup
  },
  SET_PROACTIVE_RULE_LENGTH(state, proactiveRulesLength) {
    state.proactiveRulesLength = proactiveRulesLength
  },
  UNSHIFT_PROACTIVE_RULE(state, proactiveRule) {
    state.proactiveRules = [proactiveRule].concat(state.proactiveRules)
  },
  SPLICE_PROACTIVE_RULE(state, _id) {
    const index = state.proactiveRules.findIndex(proactiveRules => {
      return proactiveRules._id === _id;
    })
    state.proactiveRules.splice(index, 1)
  },
};