import Request from './request'
import state from '../store/state'

const PATH = {
  COMPANY: '/company',
  COMPANY_LIST: '/company/all'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getCompanies = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.COMPANY_LIST}`, config)
}

const getCompany = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(`${state.config.konecta}${PATH.COMPANY}`, config)

}

const createCompany = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.post(`${state.config.konecta}${PATH.COMPANY}`, payload, config)
}

const updateCompany = (companyId, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(
    `${state.config.konecta}${PATH.COMPANY}/${companyId}`,
    payload,
    config
  )
}

export default {
  getCompanies,
  getCompany,
  createCompany,
  updateCompany
}
