export default {
  title: {
    es: 'Video llamada',
    en: 'Video call',
    pt: 'Video chamada',
  },
  tooltip: {
    es: 'Iniciar video llamada',
    en: 'Start video call',
    pt: 'Iniciar video chamada',
  },
  confirm: {
    title: {
      es: 'Atención!',
      en: 'Warning!',
      pt: 'Aviso!',
    },
    text: {
      es: '¿Está seguro que desea iniciar la video llamada?',
      en: 'Are you sure you want to start the video call?',
      pt: 'Tem certeza de que deseja iniciar a video chamada?',
    },
    acceptText: {
      es: 'Iniciar',
      en: 'Start',
      pt: 'Começar',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
  },
  clickToJoin: {
    es: 'Haz clic para unirte',
    en: 'Click to join',
    pt: 'Clique para participar',
  },
  gotToActivePanelToJoin: {
    es: 'Ir al panel de atención para unirse',
    en: 'Go to active panel to join',
    pt: 'Vá para o painel de atenção para participar',
  },
  expires: {
    es: 'Expira',
    en: 'Expires',
    pt: 'Expira',
  },
  expired: {
    es: 'Expiró',
    en: 'Expired',
    pt: 'Expirada',
  },
  notAbleToJoin: {
    es: 'No puedes unirte a esta videollamada, ya que no eres quien la creó.',
    en: 'You are not able to join this video call, since you are not the one who created it.',
    pt: 'Você não pode participar desta videochamada, pois não foi você quem a criou.'
  }
}