import get from 'lodash/get'

export default {
  SET_CHARTS_BUILDER(state, charts) {
    state.charts = charts.map(data => {
      return {...data, query: JSON.parse(data.query) }
    })
  },
  SET_CHARTS_LENGTH(state, chartsLength) {
    state.chartsLength = chartsLength
  },
  ADD_CHART_BUILDER(state, chart) {
    const chartsEmbedded = get(chart, 'chartsEmbedded', []).map((data) => ({ ...data, query: JSON.parse(data.query)}))
    state.charts = [...state.charts, { ...chart, query: JSON.parse(chart.query), chartsEmbedded }]
  },
  ADD_CHART_LENGTH(state) {
    state.chartsLength = state.chartsLength + 1;
  },
  DELETE_CHART_BUILDER(state, _id) {
    state.charts = state.charts.filter(({ _id: id }) => id !== _id)
  },
  SUBTRACT_CHART_LENGTH(state) {
    state.chartsLength = state.chartsLength - 1;
  },
  SET_TABLE_SHOW(state, chartShowTable) {
    state.chartShowTable = chartShowTable
  },
  SET_TABLE_SHOW_BODY(state, chartShowTable) {
    state.chartShowTableBody = chartShowTable
  }
};
