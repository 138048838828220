export default {
  title: {
    es: 'Credenciales NLU',
    en: 'NLU Credentials',
    pt: 'Credenciais NLU',
  },
  table: {
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    type: {
      es: 'Tipo',
      en: 'Type',
      pt: 'Tipo',
    },
    active: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo',
    },
    _createdAt: {
      es: 'Creación',
      en: 'Created',
      pt: 'Criado',
    },
    _updatedAt: {
      es: 'Actualizado',
      en: 'Updated',
      pt: 'Atualizado',
    },
  },
  validationErrors: {
    invalidName: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'El nombre de la credencial es requerido.',
        en: 'Credential name is required.',
        pt: 'Nome da credencial é necessário.',
      },
    },
    notValues: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'La entidad debe tener al menos un valor.',
        en: 'Entity value is required.',
        pt: 'O valor da entidade é necessário.',
      },
    },
  },
  add: {
    popUpTitle: {
      es: 'Agregar credencial',
      en: 'Add credential',
      pt: 'Adicionar credencial',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    type: {
      es: 'Tipo',
      en: 'Type',
      pt: 'Tipo',
    },
    active: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La credencial fue creada correctamente.',
        en: 'The credential was created.',
        pt: 'A credencial foi criada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar Credencial',
      en: 'Edit Credential',
      pt: 'Editar Credencial',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    type: {
      es: 'Tipo',
      en: 'Type',
      pt: 'Tipo',
    },
    active: {
      es: 'Activo',
      en: 'Active',
      pt: 'Ativo',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La credencial fue actualizada correctamente.',
        en: 'The credential was updated.',
        pt: 'A credencial foi atualizada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Estas por borrar una credencial de NLU, esta acción es irreversible. ¿Estás seguro?',
        en: 'You are about to erase an NLU credential, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar um credencial de NLU, esta ação é irreversível',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La credencial fue eliminada correctamente.',
        en: 'The credential was deleted.',
        pt: 'A credencial foi deletada.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
}