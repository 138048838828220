import Request from './request'
import state from '../store/state'

const PATH = {
  QUEUE: '/queue'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getQueues = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
  }

  return Request.get(
    `${state.config.konecta}${PATH.QUEUE}`,
    config
  )
}

const _postQueue = (queue) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.post(
    `${state.config.konecta}${PATH.QUEUE}`,
    queue,
    config
  )
}

const _putQueue = (queue, id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.put(
    `${state.config.konecta}${PATH.QUEUE}/${id}`,
    queue,
    config
  )
}

const _deleteQueue = (queueId) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  // await new Promise(resolve => setTimeout(resolve, 500))

  return Request.delete(
    `${state.config.konecta}${PATH.QUEUE}/${queueId}`,
    config
  )
}

export default {
  getQueues: _getQueues,
  putQueue: _putQueue,
  postQueue: _postQueue,
  deleteQueue: _deleteQueue
}
