export default {
  title: {
    es: 'No se puede eliminar esta respuesta',
    en: 'Cannot delete this answer',
    pt: 'Não é possível deletar esta resposta'
  },
  text: {
    es: 'La respuesta no puede ser eliminada porque está asociada con otros ',
    en: 'The answer cannot be deleted because it is associated with other ',
    pt: 'A resposta não pode ser deletada porque está associada a outros '
  },
  answer: {
    es: 'respuesta(s).',
    en: 'answer(s).',
    pt: 'resposta(s).'
  },
  titleShow: {
    es: 'Mostrar respuesta(s) asociada(s)',
    en: 'Show answer(s) associated',
    pt: 'Mostrar resposta(s) associada(s)'
},
  titleHide: {
    es: 'Ocultar respuesta(s) asociada(s)',
    en: 'Hide answer(s) associated',
    pt: 'Ocultar resposta(s) associada(s)'
  },
  button: {
    ok: {
      es: 'Entendido, lo tengo',
      en: 'Ok, I get it',
      pt: 'Ok, eu entendi'
    }
  }
}
