import { USER_ROLES } from './UserRoles'

export class RoleAccessCapabilities {
  constructor(roles) {
    this._metrics = false
    this._chats = false
    this._createBot = false
    this._editBot = false
    this._attentionRequests = false
    this._botMaker = false
    this._users = false

    if (roles.length > 0) {
      if (roles.includes(USER_ROLES.SUPER_ADMIN)) {
        this._metrics = true
        this._chats = true
        this._createBot = true
        this._editBot = true
        this._attentionRequests = true
        this._botMaker = true
        this._users = true
      }

      if (roles.includes(USER_ROLES.SUPERVISOR)) {
        this._metrics = true
        this._chats = true
        this._editBot = true
        this._attentionRequests = true
        this._users = true
      }

      if (roles.includes(USER_ROLES.ROLES_MANAGER)) {
        // this._metrics = true
        // this._chats = true
        // this._editBot = true
        // this._attentionRequests = true
        this._users = true
      }

      if (roles.includes(USER_ROLES.AGENT)) {
        this._chats = true
        this._attentionRequests = true
      }

      if (roles.includes(USER_ROLES.EDITOR)) {
        this._editBot = true
        this._botMaker = true
      }
    }
  }

  get createBot() {
    return this._createBot
  }

  get metrics() {
    return this._metrics
  }

  get chats() {
    return this._chats
  }

  get attentionRequests() {
    return this._attentionRequests
  }

  get editBot() {
    return this._editBot
  }

  get botMaker() {
    return this._botMaker
  }

  get users() {
    return this._users
  }
}
