import axios from 'axios'
import router from '@/router'
import state from '@/store/state'

/**
 * This function will handle some error cases from the API.
 * Navigation will be triggered if needed.
 * 
 * @todo This needs to be refactored because of the usage of `Router`
 *       the issue is that `Router` also uses the `Store` inside and
 *       this will create a circular dependency while testing.
 *       Also we are using `Router` outside the Vue scope to navigate
 *       and relying on the `Store` too. One possible solution is to
 *       create an abstraction to handle the navigation from a store
 *       watcher with a custom `errorState` and correct access to the 
 *      `Vue`, `Store` and `Router` instances.
 *
 * @param {import('axios').AxiosError} err
 * @returns {Promise<any>}
 */
function catchMiddleware(err) {
  if (err?.response?.status === 441) {
    // Expired password, must change it
    return router.push('/pages/change-password')
  }

  if (err?.response?.data === 'Banned') {
    return router.push('/pages/banned')
  }

  if (err?.response?.status === 401) {
    // Expired session, must login again
    return localStorage.getItem('issuer')
      ? router.push('/pages/okta-expired')
      : router.push('/pages/login')
  }

  return Promise.reject(err)
}

/**
 * Returns current socket ID if there is one.
 * 
 * @todo Would be great too if we can remove the usage of the
 *       state here too. The less access we have to "vue objects"
 *       outside the `Vue` scope the better.
 * 
 * @returns {string}
 */
function getSocketId() {
  return state.socket && state.socket.client ? state.socket.client.id : ''
}

export default {
  /**
   * Get resources from the API
   * 
   * @param {string} url - API final URL
   * @param {import('axios').AxiosRequestConfig} config 
   * @returns {Promise<any>}
   */
  async get(url, config) {
    return axios({
      method: 'get',
      url,
      ...config,
      headers: {
        ...config.headers,
        'X-Socket-Id': getSocketId()
      }
    }).catch(catchMiddleware)
  },
  /**
   * Create a new resource in the API
   * 
   * @param {string} url - API final URL
   * @param {import('axios').AxiosRequestConfig['data']} data - Data payload to create
   * @param {import('axios').AxiosRequestConfig} [config] 
   * @returns {Promise<any>}
   */
  async post(url, data, config) {
    return axios({
      method: 'post',
      url,
      data,
      ...config,
      headers: {
        ...config.headers,
        'X-Socket-Id': getSocketId()
      }
    }).catch(catchMiddleware)
  },
  /**
   * Modify a resource in the API
   * 
   * @param {string} url - API final URL
   * @param {import('axios').AxiosRequestConfig['data']} data - Data payload to modify
   * @param {import('axios').AxiosRequestConfig} [config] 
   * @returns {Promise<any>}
   */
  async put(url, data, config) {
    return axios({
      method: 'put',
      url,
      data,
      ...config,
      headers: {
        ...config.headers,
        'X-Socket-Id': getSocketId(),
      }
    }).catch(catchMiddleware)
  },
  /**
   * Delete a resource from the API
   * 
   * @param {string} url - API final URL
   * @param {import('axios').AxiosRequestConfig} config 
   * @returns {Promise<any>}
   */
  async delete(url, config) {
    return axios({
      method: 'delete',
      url,
      ...config,
      headers: {
        ...config.headers,
        'X-Socket-Id': getSocketId(),
      }
    }).catch(catchMiddleware)
  }
}
