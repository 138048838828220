import AnswerHistory from '../../services/answerHistory.service'

const actions = {
  async GET_ANSWER_HISTORY({ commit }, queryParams) {
    try {
      const response = await AnswerHistory.getAll(queryParams)
      commit('SET_ANSWER_HISTORY', response.data.result);
      commit('SET_ANSWER_HISTORY_LENGTH', response.data.length);
      return response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
}
export default actions