import Request from './request'
import state from '../store/state'

const PATH = {
  SCHEDULE: '/schedules'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getSchedules = (versionId=null) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const query=(versionId)?`?where[version]=${versionId}`:''
  return Request.get(`${state.config.konecta}${PATH.SCHEDULE}${query}`, config)
}

const getSchedule = scheduleId => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.SCHEDULE}/${scheduleId}`, config)
}

const createSchedule = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.SCHEDULE}`, payload, config)
}

const updateSchedule = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.SCHEDULE}/${id}`, payload, config)
}

const deleteSchedule = scheduleId => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.SCHEDULE}/${scheduleId}`, config
    )
  }

export default {
  getSchedules,
  getSchedule,
  createSchedule,
  updateSchedule,
  deleteSchedule
}
