export default {
  title: {
    es: 'Configuración de cuenta',
    en: 'Account setup',
    pt: 'Configuração da conta',
  },
  description: {
    es: 'Ya casi estás listo, solo necesitas realizar la primera configuración de tu cuenta.',
    en: 'You are almost done, now you just need to make the first configuration of your account.',
    pt: 'Você está quase terminando, agora você só precisa fazer a primeira configuração de sua conta.',
  },
  form: {
    companyName: {
      es: 'Nombre de la compañía',
      en: 'Company name',
      pt: 'Nome da empresa',
    },
    userName: {
      es: 'Nombre de usuario',
      en: 'User name',
      pt: 'Nome do usuário',
    },
    password1: {
      es: 'Contraseña',
      en: 'Password',
      pt: 'Senha',
    },
    password2: {
      es: 'Repetir contraseña',
      en: 'Repeat password',
      pt: 'Repita a senha',
    },
    error: {
      userNameAlreadyInUse: {
        es: 'No disponible, ya en uso.',
        en: 'Not available, already in use.',
        pt: 'Não disponível, já em uso.',
      },
      newPassword_invalid: {
        es: 'Debe contener al menos 8 caracteres.',
        en: 'Must have at least 8 characters.',
        pt: 'Deve ter pelo menos 8 caracteres.',
      },
      newPassword_neq_repeatNewPassword: {
        es: 'Debe repetir la nueva contraseña.',
        en: 'You must repeat the new password.',
        pt: 'Você deve repetir a nova senha.',
      },
      generic: {
        es: 'Parece que ocurrió un error.',
        en: 'It seems that an error occurred.',
        pt: 'Parece que um erro ocorreu.',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La contraseña fue cambiada exitosamente.',
        en: 'The password was successfully changed.',
        pt: 'A senha foi alterada com sucesso.',
      },
    },
  },
  backToLogin: {
    es: 'Volver al Login',
    en: 'Back To Login',
    pt: 'Voltar ao Login',
  },
  login: {
    es: 'Entrar',
    en: 'Login',
    pt: 'Acessar',
  },
}