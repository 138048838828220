// vue3-migration: for this we need to follow the steps in TECKO-5479
import Vue, { nextTick } from 'vue'

import utils from '@/assets/utils'

import vsDialog from './index.vue'

const dialogConstructor = Vue.extend(vsDialog)

let instance

export default {
  name: 'dialog',
  vsfunction(parameters) {
    instance = new dialogConstructor({
      data: parameters,
    })

    instance.$data.isPrompt = false

    instance.vm = instance.$mount()

    parameters.accept ? instance.vm.$on('accept', parameters.accept) : null
    parameters.cancel ? instance.vm.$on('cancel', parameters.cancel) : null
    utils.insertBody(instance.vm.$el)

    nextTick(() => {
      instance.active = true
    })
  }
}
