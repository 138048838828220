export default {
  tooltipRT: {
    es: 'Puede ingresar tanto texto regular como dinámico. <br/> Para insertar datos dinámicos, ingrese @ para ver y seleccionar entre las variables de datos dinámicos disponibles.',
    en: 'You can enter both regular and dynamic text. <br/> To insert dynamic data, enter the @ to see and select from the available dynamic data variables.',
    pt: 'Você pode inserir texto regular e dinâmico. <br/> Para inserir dados dinâmicos, digite @ para ver e selecionar as variáveis de dados dinâmicos disponíveis.',
  },
  improveResponse: {
    es: 'Mejorar respuesta mediante IA',
    en: 'Improve response through AI',
    pt: 'Melhore a resposta por meio da IA',
  },
  tooltipSV: {
    es: 'Escribe un cálculo aritmético entre variables, para calcular valores. <br/> Puede introducir operadores aritméticos (+ - * /), utilizar paréntesis () seleccionar variables con @. <br/> For example: <br/>(@variable_a * 3) / @variable_b',
    en: 'Write an arithmetic calculations between variables, to calculate values. <br/> You can enter arithmetic operators (+ - * /), use parenthesis() and select variables with @. <br/> For example: <br/>(@variable_a * 3) / @variable_b',
    pt: 'Escreva um cálculo aritmético entre as variáveis, para calcular valores. <br/> Você pode entrar operadores aritméticos (+ - * /), sar parênteses () e selecionar variáveis com @. <br/> For example: <br/>(@variable_a * 3) / @variable_b',
  },
}