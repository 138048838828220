import CompanyService from '../../services/company.service'

const actions = {
  async getCompanies({ commit }) {
    const response = await CompanyService.getCompanies()
    if (response && response.data) {
      commit('SET_COMPANIES', response.data)
    }
  },
  async updateCompany(context, { companyId, payload }) {
    await CompanyService.updateCompany(companyId, payload)
  }
}

export default actions
