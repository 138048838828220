import Vue from 'vue'

const COLOR = {
  DANGER: 'danger',
  WARNING: 'warning',
  SUCCESS: 'success'
}

const ICON = {
  DANGER: 'icon-warning',
  WARNING: 'icon-warning',
  SUCCESS: 'icon-check-circle'
}

const callNotify = message => {
  Vue.prototype.$vs.notify(message)
}

const notify = {
  success(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.SUCCESS,
      icon: ICON.SUCCESS,
      iconPack: "symbolicon-font"
    })
  },
  warning(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.WARNING,
      icon: ICON.WARNING,
      iconPack: "symbolicon-font"
    })
  },
  error(title, text) {
    callNotify({
      title: title,
      text: text,
      color: COLOR.DANGER,
      icon: ICON.DANGER,
      iconPack: "symbolicon-font"
    })
  }
}

/** @type {import('vue').PluginObject} */
export const notificationPlugin = {
  install(Vue) {
    Vue.prototype.$notify = notify
  }
}
