import Request from './request'
import state from '../store/state'

const PATH = {
  CONDITION: '/conditions-catalog'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = async (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
    params: {
      ...queryParams,
    }
  }
  return Request.get(`${state.config.konecta}${PATH.CONDITION}`, config)
}

const getAllWhit = (versionId=null) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const query=(versionId)?`?where[version]=${versionId}`:''
  return Request.get(`${state.config.konecta}${PATH.CONDITION}${query}`, config)
}

const getId = conditionId => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.CONDITION}/${conditionId}`, config)
}

const create = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.CONDITION}`, payload, config)
}

const update = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.CONDITION}/${id}`, payload, config)
}

const importCondition = (id, payload) => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.CONDITION}/import/${id}`, payload, config)
}

const deleteElseIf = (id, dialogId) => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.put(`${state.config.konecta}${PATH.CONDITION}/remove-else-if/${id}/${dialogId}`, {}, config)
}

const addElseIf = (id, body) => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.put(`${state.config.konecta}${PATH.CONDITION}/add-else-if/${id}`, body, config)
}

const move = (id, body) => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.put(`${state.config.konecta}${PATH.CONDITION}/move/${id}`, body, config)
}

const deleteId = conditionId => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.CONDITION}/${conditionId}`, config
    )
  }

export default {
  getAllWhit,
  create,
  update,
  deleteId,
  getAll,
  getId,
  importCondition,
  deleteElseIf,
  addElseIf,
  move,
}
