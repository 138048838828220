import Vue from 'vue'
import App from './App.vue'

// Vuesax Component Framework
import Vuesax from 'vuesax'
import 'material-icons/iconfont/material-icons.css'
import 'vuesax/dist/vuesax.css'
Vue.use(Vuesax, { theme: store.state.themeColors })

import './assets/scss/main.scss'
import './assets/css/main.css'
import './assets/css/icons-style.css'
import 'vue-multiselect/dist/vue-multiselect.min.css'

// Vue Router
import router from './router'

// Vuex Store
import store from './store/store'

// Directives
import './directives/directives'

import VueDOMPurifyHTML from 'vue-dompurify-html'

let targetAttr = ''
Vue.use(VueDOMPurifyHTML, {
  hooks: {
    beforeSanitizeAttributes: currentNode => {
      if ('target' in currentNode) {
        targetAttr = currentNode.getAttribute('target')
      }
    },
    afterSanitizeAttributes: currentNode => {
      if (targetAttr && 'target' in currentNode) {
        currentNode.setAttribute('target', targetAttr)
        currentNode.setAttribute('rel', 'noopener')
      }
    }
  }
})

import VueChartkick from 'vue-chartkick'
import Chart from 'chart.js'
Vue.use(VueChartkick, { adapter: Chart })

Vue.config.productionTip = false

import { notificationPlugin } from './plugins/notify.plugin'
import { momentPlugin } from './plugins/moment.plugin'
import { datePlugin } from './plugins/date.plugin'
import { roleNamePlugin } from './plugins/rolename.plugin'
import { imageLoad } from './plugins/imageLoad.plugin'

Vue.use(notificationPlugin)
Vue.use(momentPlugin)
Vue.use(datePlugin)
Vue.use(roleNamePlugin)
Vue.use(imageLoad)

import { VTooltip } from 'floating-vue'
import 'floating-vue/dist/style.css'
Vue.directive('tooltip', VTooltip)

import VxTooltip from './layouts/components/vx-tooltip/VxTooltip.vue'
Vue.component(VxTooltip.name, VxTooltip)

import VxCard from './components/vx-card/VxCard.vue'
Vue.component(VxCard.name, VxCard)

import VxSlider from './components/vx-slider/VxSlider.vue'
Vue.component('VxSlider', VxSlider)

import VxCheckbox from './components/vx-checkbox/VxCheckbox.vue'
Vue.component('VxCheckbox', VxCheckbox)

import VueECharts from 'vue-echarts'
import { use } from 'echarts/core'
import { BarChart, LineChart, TreemapChart, HeatmapChart, GaugeChart } from 'echarts/charts'
import {
  LegendComponent,
  TooltipComponent,
  GridComponent,
  TitleComponent,
  VisualMapComponent
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'

use([
  LegendComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  LineChart,
  CanvasRenderer,
  TreemapChart,
  VisualMapComponent,
  TitleComponent,
  GaugeChart,
  HeatmapChart
])
Vue.use(VueECharts)

new Vue({
  router,
  store,
  name: 'KonectaAdmin',
  render: h => h(App)
}).$mount('#app')
