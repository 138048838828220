/*=========================================================================================
  File Name: moduleChatGetters.js
  Description: Chat Module Getters
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default {
  chatDataOfUser: state => id => {
    return state.chats[Object.keys(state.chats).find(key => key == id)]
  },

  chatLastMessaged: (state, getters) => id => {
    if (getters.chatDataOfUser(id))
      return getters.chatDataOfUser(id).msg.slice(-1)[0]
    else return false
  },
  chatUnseenMessages: (state, getters) => id => {
    let unseenMsg = 0
    const chatData = getters.chatDataOfUser(id)
    if (chatData) {
      chatData.msg.map(msg => {
        if (!msg.isSeen && !msg.isSent) unseenMsg++
      })
    }
    return unseenMsg
  }
}
