export default {
  title: {
    es: 'Clusterización',
    en: 'Clustering',
    pt: 'Agrupamento',
  },
  createCluster: {
    title: {
      es: 'Crear cluster',
      en: 'Create cluster',
      pt: 'Criar cluster',
    },
    create: {
      es: 'Crear cluster',
      en: 'Create cluster',
      pt: 'Criar cluster',
    },
    cancel: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    messages: {
      success: {
        title: {
          es: '',
          en: '',
          pt: '',
        },
        text: {
          es: 'Se ha creado el cluster exitosamente!',
          en: 'The cluster has been created successfully!',
          pt: 'O cluster foi criado com sucesso!',
        },
      },
      error: {
        title: {
          es: 'Oops!',
          en: 'Oops!',
          pt: 'Oops!',
        },
        text: {
          es: 'Ha ocurrido un error al crear el cluster. Por favor, intente nuevamente.',
          en: 'An error occurred while creating the cluster. Please try again.',
          pt: 'Ocorreu um erro ao criar o cluster. Por favor, tente novamente.',
        },
      },
    },
  },
  clustersTable: {
    bot: {
      es: 'Bot',
      en: 'Bot',
      pt: 'Bot',
    },
    from: {
      es: 'Fecha inicial',
      en: 'From',
      pt: 'Data de início',
    },
    to: {
      es: 'Fecha final',
      en: 'To',
      pt: 'Data de início',
    },
    status: {
      es: 'Estado',
      en: 'Status',
      pt: 'Status',
    },
    statusNames: {
      ready: {
        es: 'Listo',
        en: 'Ready',
        pt: 'Pronto',
      },
      training: {
        es: 'Entrenando',
        en: 'Training',
        pt: 'Treinamento',
      },
      error: {
        es: 'Error',
        en: 'Error',
        pt: 'Erro',
      },
      finished: {
        es: 'Finalizado',
        en: 'Finished',
        pt: 'Finalizado',
      },
      created: {
        es: 'Creado',
        en: 'Created',
        pt: 'Criado',
      },
    },
    actions: {
      es: 'Acciones',
      en: 'Actions',
      pt: 'Ações',
    },
    actionsTooltips: {
      goToCenters: {
        es: 'Mostrar centros',
        en: 'Show centers',
        pt: 'Exibir centros',
      },
      downloadQuestions: {
        es: 'Descargar preguntas desconocidas',
        en: 'Download unknown questions',
        pt: 'Baixe perguntas desconhecidas',
      },
    },
  },
  centersTable: {
    title: {
      es: 'Centros',
      en: 'Centers',
      pt: 'Centros',
    },
    clusterName: {
      es: 'Nombre de cluster',
      en: 'Cluster name',
      pt: 'Nome do cluster',
    },
    totalSamples: {
      es: 'Cantidad de ejemplos',
      en: 'Samples count',
      pt: 'Número de exemplos',
    },
    similarIntents: {
      es: 'Intenciones similares',
      en: 'Similar intents',
      pt: 'Intenções semelhantes',
    },
    actions: {
      es: 'Acciones',
      en: 'Actions',
      pt: 'Ações',
    },
    actionsTooltips: {
      showWordCloud: {
        es: 'Mostrar word cloud',
        en: 'Show word cloud',
        pt: 'Mostrar word cloud',
      },
    },
    samples: {
      es: 'Ejemplos',
      en: 'Samples',
      pt: 'Exemplos',
    },
    downloadSamples: {
      es: 'Descargar ejemplos',
      en: 'Download samples',
      pt: 'Baixe exemplos',
    },
    messages: {
      getCenters: {
        error: {
          title: {
            es: 'Oops!',
            en: 'Oops!',
            pt: 'Oops!',
          },
          text: {
            es: 'Ha ocurrido un error al obtener los centros. Por favor, intente nuevamente.',
            en: 'An error occurred while obtaining the centers. Please try again.',
            pt: 'Ocorreu um erro na obtenção dos centros. Por favor, tente de novo.',
          },
        },
      },
    },
  },
  download: {
    es: 'Descargar',
    en: 'Download',
    pt: 'Baixe',
  },
  training: {
    messages: {
      success: {
        es: 'La pregunta ha sido entrenada correctamente!',
        en: 'The question has been trained succesfully!',
        pt: 'A pergunta foi treinada com sucesso!',
      },
    },
  },
}