import Roles from '../models/Roles'

/** @type {import('vue').PluginObject} */
export const roleNamePlugin = {
  install(Vue) {
    // vue3-migration: Vue.prototype.$role_name replace by Vue.config.globalProperties.$role_name
    Vue.prototype.$role_name = (roleId, languageSelected) => {
      if (!roleId) {
        return ''
      }
      return Roles[roleId].name[languageSelected]
    }
  }
}
