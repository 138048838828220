export default {
    title: {
        es: 'Single Sign-On',
        en: 'Single Sign-On',
        pt: 'Single Sign-On',
    },
    description: {
        es: 'SSO (Single Sign-on) permite a los usuarios acceder a Konecta utilizando un solo conjunto de credenciales de inicio de sesión, agilizando el proceso de autenticación.',
        en: 'SSO (Single Sign-on) allows users to access Konecta using just one set of login credentials, streamlining the authentication process.',
        pt: 'SSO (Single Sign-on) permite aos usuários acessar o Konecta usando apenas um conjunto de credenciais de login, agilizando o processo de autenticação.',
    },
    identity:{
        title: {
            es: 'Proveedor de identidad',
            en: 'Identity Provider',
            pt: 'Provedor de identidade',
        },
        provider: [
            {
                value: 'konecta'
            },
            {
                value: 'okta'
            },
        ],
    },
    okta:{
        es: 'Okta',
        en: 'Okta',
        pt: 'Okta',
    },
    edit:{
        es: 'Editar credenciales',
        en: 'Edit credentials',
        pt: 'Editar credenciais',
    },
    oktaAuth:{
        oidc:{
            es: 'Authorization server',
            en: 'Authorization server',
            pt: 'Authorization server',
        },
        clientId:{
            es: 'Client ID',
            en: 'Client ID',
            pt: 'Client ID',
        }
    },
    successful:{
        title:{
            es: 'Exitoso!',
            en: 'Successful!',
            pt: 'Sucesso'
        },
        description:{
            es: 'Las credenciales fueron guardadas con éxito',
            en: 'The credentials were saved successfully.',
            pt: 'As credenciais são guardadas com sucesso'
        }

    },

    headsUp:{
        title:{
            es: 'Aviso!',
            en: 'Heads up!',
            pt: 'Atenção!',
        },
        descriptionFirstLine:{
            es: 'Revise los detalles del proveedor de SSO antes de guardar.',
            en: 'Review SSO provider details before saving.',
            pt: 'Revise os detalhes do provedor de SSO antes de salvar.',
        },
        descriptionSecondLine:{
            es: 'La información incorrecta puede impedir el inicio de sesión y deberá comunicarse con el administrador.',
            en: 'Incorrect information may prevent login and you will need to contact the administrator.',
            pt: 'Informações incorretas podem impedir o login e você precisará entrar em contato com o administrador.',
        },
        save:{
            es: 'Guardar',
            en: 'Save',
            pt: 'Salvar',
        },
        edit:{
            es: 'Regresar a editar',
            en: 'Back to edit',
            pt: 'Voltar a editar',
        },
        requiredFields:{
            textRequired:{
                es: 'El campo es obligatorio.',
                en: 'The field is required.',
                pt: 'O campo é obrigatório.'
            },
            textFieldsRequired:{
                es: 'Hay campos obligatorios que no se han rellenado.',
                en: 'There are mandatory fields that have not been filled in.',
                pt: 'Há campos obrigatórios que não foram preenchidos.'
            },
        }
    }
}
