import filter from 'lodash/filter'

export default {
  tagCategoriesSet(state, tagCategory) {
    state.tagCategories = tagCategory;
  },
  tagCategoriesCreate(state, tagCategory) {
    state.tagCategories = [tagCategory, ...state.tagCategories];
  },
  tagCategoryUpdate(state, tagCategory) {
    const index = state.tagCategories.findIndex((category) => category._id === tagCategory._id)
    const categoriesTemp = [...state.tagCategories]
    if (index !== -1) categoriesTemp[index] = tagCategory;
    state.tagCategories = categoriesTemp;
  },
  tagCategoryDelete(state, tagCategory) {
    const index = state.tagCategories.findIndex((category) => category._id === tagCategory._id)
    if (index !== -1) state.tagCategories = filter(state.tagCategories, (category) => category._id !== tagCategory._id)
  }
};