export default {
  trainButton: {
    es: 'Entrenar',
    en: 'Train',
    pt: 'Treinar',
  },
  cancelButton: {
    es: 'Cancelar',
    en: 'Cancel',
    pt: 'Cancelar'
  },
  popup: {
    title: {
      es: 'Entrenar nuevo ejemplo',
      en: 'Train new example',
      pt: 'Treinar novo exemplo',
    },
    inputLabel: {
      es: 'Nuevo ejemplo',
      en: 'New example',
      pt: 'Novo exemplo',
    },
    intent: {
      es: 'Intención',
      en: 'Intent',
      pt: 'Intenções',
    },
    answer: {
      es: 'Respuesta',
      en: 'Answer',
      pt: 'Respuesta'
    },
    noExamples: {
      es: 'Aún no se crearon ejemplos.',
      en: 'No examples created yet.',
      pt: 'Nenhum exemplo criado ainda.'
    }
  }
}