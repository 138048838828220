import { USER_ROLES } from './Roles/UserRoles';
import { PROFILES_USER_CREATION } from '../constants/constants';

export default {
  [USER_ROLES.SYS_ADMIN]: {
    defaultName: 'company_sys_admin',
    name: {
      es: 'Sys Admin',
      en: 'Sys Admin',
      pt: 'Sys Admin'
    },
    id: '6077203875f88029147af507',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.SYS_ADMIN]
  },
  [USER_ROLES.GUEST]: {
    defaultName: 'company_guest',
    name: {
      es: 'Invitado',
      en: 'Guest',
      pt: 'Convidado'
    },
    id: '662ff10cd2fcf35131869e88',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.GUEST]
  },
  [USER_ROLES.SUPER_ADMIN]: {
    defaultName: 'company_superadmin',
    name: {
      es: 'Super Administrador',
      en: 'Superadmin',
      pt: 'Superadministrador',
    },
    id: '5cd9a8f9f99a38006e7b8b1c',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.SUPER_ADMIN]
  },
  [USER_ROLES.EDITOR]: {
    defaultName: 'company_editor',
    name: {
      es: 'Bot Editor',
      en: 'Bot Editor',
      pt: 'Bot Editor',
    },
    id: '5ca511218f8f640063c52468',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.EDITOR]
  },
  [USER_ROLES.SUPERVISOR]: {
    defaultName: 'company_supervisor',
    name: {
      es: 'Chat Supervisor',
      en: 'Chat Supervisor',
      pt: 'Chat Supervisor',
    },
    id: '5cd09eb2e9d215006fff774b',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.SUPERVISOR]
  },
  [USER_ROLES.AGENT]: {
    defaultName: 'company_agent',
    name: {
      es: 'Agente',
      en: 'Agent',
      pt: 'Agente',
    },
    id: '5cd08f88e9d215006fff7716',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.AGENT]
  },
  [USER_ROLES.ROLES_MANAGER]: {
    defaultName: 'company_roles_manager',
    name: {
      es: 'Administrador de Roles',
      en: 'Role Manager',
      pt: 'Gerente de função',
    },
    id: '5e98728cce9d06df60693b6a',
    canCreate: PROFILES_USER_CREATION[USER_ROLES.ROLES_MANAGER]
  }
}
