import Request from './request'
import state from '../store/state'

const PATH = {
  CHANNEL: '/channel',
}

/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _getChannels = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.CHANNEL}`,
    config
  )
}
export default {
  getChannels: _getChannels,
}
