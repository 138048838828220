import QueueService from '../../services/queues.service'

const actions = {
  async GET_QUEUES({ state }) {
    try{
      const response = await QueueService.getQueues();
      state.queues = response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
  UNSET_QUEUE({ state }) {
    state.selectedQueue = undefined;
  },
  async SAVE_QUEUE({ state }, queue) {
    delete queue.isDefaultQueue;
    if (queue.id) {
      const queueId = queue.id;
      delete queue.id; 
      const response = await QueueService.putQueue(queue, queueId);
      const updatedQueue = response.data
      if (updatedQueue._id) {
        const updatedQueueIndex = state.queues.findIndex(q => q._id === queueId);
        if (updatedQueueIndex > -1) {
            const newQueues = state.queues;
            newQueues[updatedQueueIndex] = updatedQueue;
            state.queues = newQueues;
        }
      }
    } else {
      const response = await QueueService.postQueue(queue);
      const updatedQueue = response.data
      if (updatedQueue._id) {
        const newQueues = state.queues;
        newQueues.push(updatedQueue);
        state.queues = newQueues;
      }
    }
    state.selectedQueue = undefined;
  },
  async DELETE_QUEUE({ state }, queueId) {
    const queueIndex = state.queues.findIndex(q => q._id === queueId);
    if (queueIndex > -1) {
      await QueueService.deleteQueue(queueId);
      const queues = state.queues;
      queues.splice(queueIndex, 1)
      state.queues = queues;
    }
  },
  SET_QUEUE({ state }, queueId) {
    if (queueId) {
      const selectedQueue = state.queues[state.queues.findIndex(q => q._id === queueId)];
      if (selectedQueue) {
          state.selectedQueue = selectedQueue;
      }
    } else {
      state.selectedQueue = {
        name: '',
        description: '',
        agents: [],
        intents: [],
        tags: [],
        channels: [],
        noDialogDetectedTakeover: false,
      }
    }
  }
}

export default actions
