import Request from './request'
import state from '../store/state'

const PATHS = {
  GET_ALL: '/tag-category',
  UPDATE_ONE: '/tag-category',
  CREATE_ONE: '/tag-category'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = async (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
    params: {
      ...queryParams
    }
  }
  return Request.get(`${state.config.konecta}${PATHS.GET_ALL}`, config)
}

const updateOne = async ({ _id, body }) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
  }
  return Request.put(`${state.config.konecta}${PATHS.GET_ALL}/${_id}`, body, config)
}

const createOne = async (body) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
  }
  return Request.post(`${state.config.konecta}${PATHS.GET_ALL}`, body, config)
}

const deleteOne = async (_id) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
  }
  return Request.delete(`${state.config.konecta}${PATHS.GET_ALL}/${_id}`, config)
}

export default {
  getAll,
  updateOne,
  createOne,
  deleteOne
}
