import Validations from '../../store/Validations'

export class NLU {
  constructor(platform = 'rasa_v2', culture = 'es') {
    this._platform = platform

    if (culture.includes('es')) {
      this._culture = 'es-ES'
    } else if (culture.includes('en')) {
      this._culture = 'en-EN'
    } else {
      this._culture = 'pt-BR'
    }
  }

  // Platform

  get platform() {
    return this._platform
  }

  set platform(value) {
    this._platform = value
  }

  get culture() {
    return this._culture
  }

  set culture(value) {
    this._culture = value
  }

  isPlatformValid() {
    return !Validations.isEmpty(this._platform)
  }

  isCultureValid() {
    return !Validations.isEmpty(this._culture)
  }

  isValid() {
    return this.isPlatformValid() && this.isCultureValid()
  }

  getNluToSave() {
    return {
      platform: this.platform,
      culture: this.culture
    }
  }

  validate(validations) {
    validations.nlu.platform = !this.isPlatformValid()
    validations.nlu.culture = !this.isCultureValid()
  }
}
