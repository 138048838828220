const mutations = {
  SET_COMPANIES: (state, companies) => {
    state.companies = companies
  },
  TOGGLE_COMPANY_STATUS: (state, companyId) => {
    const companyIndex = state.companies.findIndex(
      company => company._id === companyId
    )
    state.companies[companyIndex].enabled = !state.companies[companyIndex]
      .enabled
  }
}

export default mutations
