import { themeConfig } from '@/../themeConfig.js'
import languages from '@/languages/languages'
import { CHAT_USER_STATUS } from '@/constants/constants'
import {
  appActiveUserFactory,
  companyLogoFactory,
  themeFactory,
  themeColorsFactory,
  themePrimaryColorFactory,
  languagesFactory,
  chatGeneralSettingsFactory
} from './factories'

const session = localStorage.session && JSON.parse(localStorage.session)

const state = {
  isSidebarActive: true,
  sidebarWidth: 'default',
  reduceButton: themeConfig.sidebarCollapsed,
  sidebarItemsMin: false,
  AppActiveUser: appActiveUserFactory(),
  companyLogo: companyLogoFactory(),
  theme: themeFactory(),
  themeColors: themeColorsFactory(),
  themePrimaryColor: themePrimaryColorFactory(),
  chatTransitionSettingsScope: 'company',
  chatTransitionSettings: {
    finish: {},
    transfer: {}
  },
  chatGeneralSettings: chatGeneralSettingsFactory(),
  videoCall: {
    active: false,
    config: {
      url: ''
    }
  },

  security: {
    ipFiltering: false
  },

  config: {
    konectaWidget: '',
    konectaWidgetStaging: '',
    konecta: '',
    chat: '',
    videocall: '',
    environment: null,
  },

  session: {},

  bot: {
    name: '',
    platform: ''
  },

  attachmentFile: {},

  lang: languages,
  languageSelected: session?.lang || 'en',
  languages: languagesFactory(),
  newBotLanguage: session?.lang || 'en',

  activeChatUser: null,
  newChatUserName: null,
  activeChatMessages: [], // mensajes del chat activo
  suggestedChatMessage: null,
  sentSuggestion: false,
  chatUserStatus: CHAT_USER_STATUS.offline,

  maximizeChatContainer: false, // maximizar ventana de chat

  contains: '',
  companyId: '',
  companyAuthKey: '',

  botsToSubscribe: [],
  queuesToSubscribe:[],
  chatsToSubscribe: [],
  socket: null,

  changePassword: false,
  useCyberbank: false,
  useStaffManagement: false
}

export default state
