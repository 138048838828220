import moment from 'moment'

/** @type {import('vue').PluginObject} */
export const datePlugin = {
  install(Vue) {
    // vue3-migration: Vue.prototype.$date replace by Vue.config.globalProperties.$date
    Vue.prototype.$date = (value, fullDate) => {
      if (Object.prototype.toString.call(value) !== "[object Date]") {
        // If value is not of type Date, we convert it to avoid Moment warnings
        value = new Date(value)
      }
      if (!fullDate) return moment(value).format('DD MMM')
      return moment(value).format('DD MMM YYYY')
    }
  }
}
