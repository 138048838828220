export default {
  namespaced: true,
  state: {
    variables: [],
  },
  getters: {
    variables: state => state.variables,
  },
  mutations: {
    SET_VARIABLES(state, variables) {
      state.variables = variables;
    },
  },
};