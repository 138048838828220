export default {
    title: {
      es: 'Cambia tu contraseña',
      en: 'Change your password',
      pt: 'Mude sua senha',
  },
  titleExpiration: {
    es: 'Contraseña caducada',
    en: 'Password expired',
    pt: 'Senha expirada',
  },
    description: {
      es: 'Tu contraseña ha expirado, por favor ingresa una nueva contraseña.',
      en: 'Your password has expired, please enter your new password.',
      pt: 'Sua senha caducou, por favor digite uma nova senha.',
  },
  descriptionExpiration: {
    es: 'Tu contraseña ha caducado. Por favor, sigue los criterios a continuación para crear una nueva contraseña.',
    en: 'Your password has expired. Please follow the criteria below to create a new password.',
    pt: 'Sua senha expirou. Por favor, siga os critérios abaixo para criar uma nova senha.',
  },
    form: {
      currentPassword: {
        es: 'Contraseña actual',
        en: 'Current password',
        pt: 'Senha atual',
      },
      newPassword: {
        es: 'Nueva contraseña',
        en: 'New password',
        pt: 'Nova senha',
      },
      repeatNewPassword: {
        es: 'Confirma la contraseña',
        en: 'Confirm password',
        pt: 'Confirme a senha',
      },      
      placeholder: {
        currentPassword: {
          es: 'Ingresa la contraseña caducada',
          en: 'Enter the expired password',
          pt: 'Digite a senha expirada',
        },
        newPassword: {
          es: 'Ingresa la nueva contraseña',
          en: 'Enter new password',
          pt: 'Digite a nova senha',
        },
        repeatNewPassword: {
          es: 'Reingresa la nueva contraseña',
          en: 'Re-enter new password',
          pt: 'Digite novamente a nova senha',
        },
      },
      error: {
        newPassword_invalid: {
          es: 'Debe contener al menos 8 caracteres.',
          en: 'Must have at least 8 characters.',
          pt: 'Deve ter pelo menos 8 caracteres.',
        },
        newPassword_eq_CurrentPassword: {
          es: 'La nueva contraseña no puede ser igual a la contraseña actual.',
          en: 'The new password cannot be the same as the current password.',
          pt: 'A nova senha não pode ser a mesma que a atual.',
        },
        newPassword_neq_repeatNewPassword: {
          es: 'Debe repetir la nueva contraseña.',
          en: 'You must repeat the new password.',
          pt: 'Você deve repetir a nova senha.',
        },
        401: {
          es: 'Contraseña actual incorrecta.',
          en: 'Incorrect current password.',
          pt: 'Senha atual incorreta.',
        },
        generic: {
          es: 'Parece que ocurrió un error.',
          en: 'It seems that an error occurred.',
          pt: 'Parece que ocorreu um erro.',
        },
      },
      success: {
        title: {
          es: 'Contraseña actualizada',
          en: 'Password changed',
          pt: 'Senha atualizada',
        },
        text: {
          es: 'La contraseña fue cambiada exitosamente. Se cerrará la sesión. Por favor, inicie sesión nuevamente.',
          en: 'The password was successfully changed. You will be logged out. Please, log in again.',
          pt: 'A senha foi mudada com sucesso. A sessão será encerrada. Por favor, faça login novamente.',
        },
      },
    },
    backToLogin: {
      es: 'Volver al Login',
      en: 'Back To Login',
      pt: 'Voltar ao Login',
    },
    changePassword: {
      es: 'Guardar nueva contraseña',
      en: 'Save new password',
      pt: 'Salvar nova senha',
    },
  }