import ProactiveRule from '../../services/proactiveRule.service'

export default {
  async GET_PROACTIVE_RULES({ commit }, queryParams) {
    try {
      const response = await ProactiveRule.getAll(queryParams)
      commit('SET_PROACTIVE_RULE', response.data.result);
      commit('SET_PROACTIVE_RULE_LENGTH', response.data.length);
      return response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
};