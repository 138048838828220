export default {
  errorLoadFontsDescription: {
    es: 'Error en cargar las opciones de fuentes.',
    en: 'Error loading font options.',
    pt: 'Erro ao carregar opções de fonte.',
  },
  errorLoadFontsTitle: {
    en: 'Font selector error',
    es: 'Error del selector de fuente',
    pt: 'Erro no seletor de fontes',
  }
}