export default {
  title: {
    es: 'Iniciar sesión',
    en: 'Login',
    pt: 'Acessar',
  },
  description: {
    es: 'Bienvenido de nuevo, por favor inicia sesión con tu cuenta.',
    en: 'Welcome back, please login to your account.',
    pt: 'Bem-vindo de volta, por favor, faça login na sua conta.',
  },
  rememberMe: {
    es: 'Recordarme',
    en: 'Remember me',
    pt: 'Lembre-se de mim',
  },
  forgotPassword: {
    es: '¿Olvidaste tu contraseña?',
    en: 'Forgot your password?',
    pt: 'Esqueceu sua senha?',
  },
  goBackText: {
    es: 'Iniciando sesión como ',
    en: 'Logging in as ',
    pt: 'Entrando como',
  },
  goBackQuestion: {
    es: 'No eres tu?',
    en: 'Not you?',
    pt: 'Não é você?',
  },
  serviceWorker: {
    updateTitle: {
      es: 'Actualización disponible',
      en: 'Update available',
      pt: 'Atualização disponível',
    },
    updateText: {
      es: "Importante: se ha encontrado una nueva versión de la aplicación web y es necesario actualizar para continuar. Haz clic en 'Actualizar ahora' para actualizar y continuar.",
      en: "Important: a new version of the web application has been found and it's necessary to update in order to continue using the application. Click 'Update now' to update and continue.",
      pt: "Importante: foi encontrada uma nova versão da aplicação web e é necessário atualizar para continuar usando a aplicação. Clique em 'Atualizar agora' para atualizar e continuar.",
    },
    updateButton: {
      es: 'Actualizar ahora',
      en: 'Update now',
      pt: 'Atualizar agora',
    }
  },
  expired:{
    title:{
      es: 'Sesión expirada',
      en: 'Expired Session',
      pt: 'Sessão expirada',
    },
    description:{
      es: 'Tu sesión se ha cerrado automáticamente debido a inactividad.\n' +
          'Vuelva a iniciar sesión con su usuario actual en Okta o inicie sesión con otro usuario.',
      en: 'You have been automatically logged out due to inactivity. \n' +
          'Please log back in using your current user in Okta, or log in with another one.',
      pt: 'Você foi desconectado automaticamente devido à inatividade. \n' +
      'Por favor, faça login novamente usando seu usuário atual no Okta ou faça login com outro usuário.',
    },
    ssoButton:{
      es: 'Continuar con Okta',
      en: 'Continue with Okta',
      pt: 'Continuar com Okta',
    },
    anotherUser:{
      es: 'Ingresar con otro usuario',
      en: 'Log in with another user',
      pt: 'Entrar com outro usuário',
    },
  },
  form: {
    email: {
      es: 'Correo Electrónico o Nombre de Usuario',
      en: 'Email or Username',
      pt: 'E-mail ou nome de usuário',
    },
    password: {
      es: 'Contraseña',
      en: 'Password',
      pt: 'Senha',
    },
    loginButton: {
      es: 'Entrar',
      en: 'Login',
      pt: 'Acessar',
    },
    loginOkta: {
      es: 'Entrar con Okta',
      en: 'Login with Okta',
      pt: 'Acessar Okta',
    },
    continueButton: {
      es: 'Continuar',
      en: 'Continue',
      pt: 'Continuar',
    },
    selectDomainLabel: {
      es: 'Dominios',
      en: 'Domains',
      pt: 'Domínios',
    },
    response: {
      success: {
        title: {
          es: 'Bienvenido',
          en: 'Welcome',
          pt: 'Bem-vindo',
        },
        description: {
          es: 'Has accedido exitosamente',
          en: 'You have logged in successfully',
          pt: 'Você se logou com sucesso',
        },
      },
      error400: {
        title: {
          es: 'Incorrecto',
          en: 'Incorrect',
          pt: 'Incorreto',
        },
        description: {
          es: 'Correo electrónico o contraseña no coinciden',
          en: 'Wrong email or password',
          pt: 'E-mail ou senha errados',
        },
      },
    },
  },
}
