/**
 * Determines if the keys provided are included on the permissions array
 * 
 * @typedef {Object} ViewPermissionMap
 * @property {string | string[]} key
 * @property {string[]} [permissions=[]]
 * 
 * @param {ViewPermissionMap} param
 * @returns {boolean}
 */
export const isAuthorized = ({ key, permissions = [] }) => {
  if (key === '*') { // wildcard
    return true;
  }

  if (permissions.indexOf(key) > -1) {
    return true;
  }

  if(Array.isArray(key)) {
    return key.some(k => permissions.includes(k))
  }

  if (key.startsWith('AddBot')) {
    const args = key.split(' isEditing:');
    const component = args[0];
    const isEditing = args[1] === 'true';
    switch (component) {
      case 'AddBot.generalSettings':
      case 'AddBot.isEngineSettingsEnabled':
      case 'AddBot.isSessionSettingsEnabled':
      case 'AddBot.botAvailability':
      case 'AddBot.users':
      case 'AddBot.queues':
        return isEditing
          ? permissions.indexOf('konecta.bot_maker.edit_bot_settings') > -1
          : permissions.indexOf('konecta.bots.add') > -1
      case 'AddBot.intentions':
        return permissions.indexOf('konecta.bot_maker.catalogs') > -1
      default:
        return false;
    }
  }

  return false;
};

