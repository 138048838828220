import Request from './request'
import state from '../store/state'

const PATH = {
  COMPANY: '/company'
}
/**
 * Get token
 *
 * @returns {String} with the token retrieved from the local storage
 */
const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const _saveCompanyTheme = theme => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    theme: theme
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _getCompanySettings = () => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(state.config.konecta + PATH.COMPANY, config)
}

const _saveCompanyLanguage = lang => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    lang: lang
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveCompanyPasswordValidity = passwordValidity => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    passwordValidity
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveChatTransitionSettings = chatTransitionSettings => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    chatTransitionSettings
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveCompanySecuritySettings = security => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    security
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveChatTransitionSettingsScope = chatTransitionSettingsScope => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    chatTransitionSettingsScope
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveChatGeneralSettings = chatGeneralSettings => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const payload = {
    priorityQueueSettings: chatGeneralSettings
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

const _saveCompanySSOSettings = payload => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.put(state.config.konecta + PATH.COMPANY, payload, config)
}

export default {
  saveCompanyTheme: _saveCompanyTheme,
  getCompanySettings: _getCompanySettings,
  saveCompanyLanguage: _saveCompanyLanguage,
  saveCompanyPasswordValidity: _saveCompanyPasswordValidity,
  saveChatTransitionSettings: _saveChatTransitionSettings,
  saveChatTransitionSettingsScope: _saveChatTransitionSettingsScope,
  saveChatGeneralSettings: _saveChatGeneralSettings,
  saveCompanySecuritySettings: _saveCompanySecuritySettings,
  saveCompanySSOSettings: _saveCompanySSOSettings
}
