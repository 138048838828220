export default {
  title: {
    es: 'Restablecimiento de contraseña',
    en: 'Password reset',
    pt: 'Redefinição de senha',
  },
  description: {
    es: 'Por favor, sigue los criterios a continuación para restablecer tu contraseña.',
    en: 'Please follow the criteria below to reset your password.',
    pt: 'Por favor, siga os critérios abaixo para redefinir sua senha.',
  },
  form: {
    newPassword: {
      es: 'Nueva contraseña',
      en: 'New password',
      pt: 'Nova senha',
    },
    repeatNewPassword: {
      es: 'Confirma la contraseña',
      en: 'Confirm password',
      pt: 'Confirme a senha',
    },
    placeholder: {
      newPassword: {
        es: 'Ingresa la nueva contraseña',
        en: 'Enter new password',
        pt: 'Digite a nova senha',
      },
      repeatNewPassword: {
        es: 'Reingresa la nueva contraseña',
        en: 'Re-enter new password',
        pt: 'Digite novamente a nova senha',
      },
    },
    error: {
      newPassword_invalid: {
        es: 'Debe contener al menos 8 caracteres.',
        en: 'Must have at least 8 characters.',
        pt: 'Deve ter pelo menos 8 caracteres.',
      },
      newPassword_neq_repeatNewPassword: {
        es: 'Debe repetir la nueva contraseña.',
        en: 'You must repeat the new password.',
        pt: 'Você deve repetir a nova senha.',
      },
      tokenHasExpired: {
        es: 'El token ha expirado.',
        en: 'The token has expired.',
        pt: 'O token já venceu.',
      },
      generic: {
        es: 'Parece que ocurrió un error.',
        en: 'It seems that an error occurred.',
        pt: 'Parece que ocorreu um erro.',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La contraseña fue cambiada exitosamente.',
        en: 'The password was successfully changed.',
        pt: 'A senha foi mudada com sucesso.',
      },
    },
  },
  backToLogin: {
    es: 'Volver al Login',
    en: 'Back To Login',
    pt: 'Voltar ao Login',
  },
  changePassword: {
    es: 'Cambiar Contraseña',
    en: 'Change Password',
    pt: 'Mude a senha',
  },
}