export default {
  conditionsCAT(state) {
    return state.conditionsCAT;
  },
  conditionsCATLength(state) {
    return state.conditionCATLength;
  },
  openCruPopup: state => {
    return state.openCruPopup
  },
};