export default {
  title: {
    es: 'Métricas',
    en: 'Metrics',
    pt: 'Métricas'
  },
  showMore: {
    es: 'Mostrar más',
    en: 'Show more',
    pt: 'Mostre mais'
  },
  showLess: {
    es: 'Mostrar menos',
    en: 'Show less',
    pt: 'Mostre menos'
  },
  filters: {
    title: {
      es: 'Filtrar por',
      en: 'Filter by',
      pt: 'Filtrar por'
    },
    bot: {
      label: {
        es: 'Bot',
        en: 'Bot',
        pt: 'Bot'
      }
    },
    bots: {
      label: {
        es: 'Bots',
        en: 'Bots',
        pt: 'Bots'
      }
    },
    criterion: {
      label: {
        es: 'Criterio',
        en: 'Criterion',
        pt: 'Critério'
      },
      placeholder: {
        es: 'Seleccionar Criterio',
        en: 'Select Criterion',
        pt: 'Selecione Critério'
      },
      selectedLabel: {
        es: 'Seleccionado',
        en: 'Selected',
        pt: 'Selecionado',
      },
      deselectLabel: {
        es: 'Presione enter para eliminar',
        en: 'Press enter to remove',
        pt: 'Pressione digitar para remover',
      },
    },
    user: {
      label: {
        es: 'Usuario',
        en: 'User',
        pt: 'User'
      }
    },
    users: {
      label: {
        es: 'Usuarios',
        en: 'Users',
        pt: 'Users'
      }
    },
    queue: {
      label: {
        es: 'Cola',
        en: 'Queue',
        pt: 'Fila'
      }
    },
    queues: {
      label: {
        es: 'Colas',
        en: 'Queues',
        pt: 'Filas'
      }
    },
    range: {
      lastWeek: {
        es: 'Últ. semana',
        en: 'Last week',
        pt: 'Semana passada'
      },
      lastMonth: {
        es: 'Últ. mes',
        en: 'Last month',
        pt: 'Mês passado'
      },
      date: {
        es: 'Fecha',
        en: 'Date',
        pt: 'Data'
      },
      rangeSeparator: {
        es: ' al ',
        en: ' to ',
        pt: ' para '
      },
      rangePlaceholder: {
        es: 'Seleccione rango de fechas',
        en: 'Select dates range',
        pt: 'Selecione datas'
      },
      clear: {
        es: 'Limpiar filtro',
        en: 'Clear filter',
        pt: 'Limpar filtro'
      },
      reset: {
        es: 'Última semana',
        en: 'Last week',
        pt: 'Semana passada'
      },
      messageDeadline: {
        es: 'La fecha límite para crear clusters es hoy.',
        en: 'The deadline to create clusters is today.',
        pt: 'O prazo para criar clusters é hoje.'
      }
    },
    intent: {
      label: {
        es: 'Intenciones',
        en: 'Intents',
        pt: 'Intenções'
      },
      placeholder: {
        single: {
          es: 'Seleccione intención',
          en: 'Select intent',
          pt: 'Selecione a intenção'
        },
        multiple: {
          es: 'Seleccione intenciones',
          en: 'Select intents',
          pt: 'Selecione as intenções'
        }
      },
      allIntents: {
        es: 'Todas las intenciones',
        en: 'All intents',
        pt: 'Todas as intenções'
      },
      notDetected: {
        es: 'No detectadas',
        en: 'Not detected',
        pt: 'Não detectado'
      }
    },
    companyStatus: {
      label: {
        es: 'Estado de Organización',
        en: 'Tenant status',
        pt: 'Situação de locatário'
      },
      placeholder: {
        es: 'Buscar Organización',
        en: 'Search Tenant',
        pt: 'Pesquisar Organização'
      }
    },
  },
  filtersNews: {
    criterion: {
      es: 'Criterio ',
      en: 'Criterion ',
      pt: 'Critério'
    },
    variable: {
      es: 'Variable',
      en: 'Variable',
      pt: 'Variável'
    },
    function: {
      es: 'Función ',
      en: 'Function ',
      pt: 'Função'
    },
    values: {
      es: 'Valor ',
      en: 'Value ',
      pt: 'Valor'
    },
    results_1:{
      es: 'Resultados cumplen',
      en: 'Results match',
      pt: 'Resultados encontrados'
    },
    results_2:{
      es: 'los siguientes criterios ',
      en: 'of the following criteria ',
      pt: 'dos seguintes critérios '
    },
    clear_filter:{
      es: 'Borrar todos los filtros ',
      en: 'Clear all filters ',
      pt: 'Limpar todos os filtros '
    },
    add_criteria:{
      es: 'Agregar criterios ',
      en: 'Add criteria ',
      pt: 'Adicionar critérios '
    },
    apply_filters:{
      es: 'Aplicar filtros ',
      en: 'Apply filters ',
      pt: 'Aplicar filtros '
    },
    label_bot_filter:{
      es: 'Bot ',
      en: 'Bot ',
      pt: 'Bot '
    },
    label_date_filter:{
      es: 'Fecha ',
      en: 'Date ',
      pt: 'Data '
    },
    showStagingChats: {
      es: 'Mostrar chats de prueba',
      en: 'Show staging chats ',
      pt: 'Mostrar chats de teste'
    },
    contentFilteringWarning: {
      title: {
        es: 'Cambie el filtro de fechas',
        en: 'Change the date filter',
        pt: 'Alterar o filtro de data'
      },
      text: {
        es: 'El rango máximo de fechas para filtrar por contenido de mensajes es de 14 días.',
        en: 'The maximum date range to filter by message content is 14 days.',
        pt: 'O intervalo máximo de datas para filtrar por conteúdo da mensagem é de 14 dias.'
      },
    },
    answersWarning: {
      title: {
        es: 'Cambie el filtro de fechas',
        en: 'Change the date filter',
        pt: 'Alterar o filtro de data'
      },
      text: {
        es: 'El rango máximo de fechas para filtrar por respuestas es de 14 días.',
        en: 'The maximum date range to filter by answers is 14 days.',
        pt: 'O intervalo máximo de datas para filtrar por respostas é de 14 dias.'
      },
    },
    tagsWarning: {
      title: {
        es: 'Cambie el filtro de fechas',
        en: 'Change the date filter',
        pt: 'Alterar o filtro de data'
      },
      text: {
        es: 'El rango máximo de fechas para filtrar por tags es de 14 días.',
        en: 'The maximum date range to filter by tags is 14 days.',
        pt: 'O intervalo máximo de datas para filtrar por tags é de 14 dias.'
      },
    },
    variableContainsWarning: {
      title: {
        es: 'Cambie el filtro de fechas',
        en: 'Change the date filter',
        pt: 'Alterar o filtro de data'
      },
      text: {
        es: 'El rango máximo de fechas para filtrar por contenido de variables es de 14 días.',
        en: 'The maximum date range to filter by variables content is 14 days.',
        pt: 'O intervalo máximo de datas para filtrar por conteúdo de variáveis é de 14 dias.'
      },
    },
    variableContainsLengthWarning: {
      title: {
        es: 'Longitud del texto',
        en: 'Text length',
        pt: 'Comprimento do texto'
      },
      text: {
        es: 'Para búsquedas en contenido de variables el largo mínimo es de 3 caracteres.',
        en: 'For searches on variable content the minimum length is 3 characters.',
        pt: 'Para pesquisas de conteúdo variável o comprimento mínimo é de 3 caracteres.'
      },
    },
  },
  reportMessages: {
    text: {
      es: 'Error al obtener los datos del gráfico ',
      en: 'Failed to get data from chart ',
      pt: 'Falha ao obter dados do gráfico'
    }
  }
}
