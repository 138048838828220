import general from './general'
import login from './login'
import attentionRequests from './attentionRequests'
import botMaker from './botMaker'
import metrics from './metrics'
import errors from './errors'
import users from './users'
import sidebar from './sidebar'
import resetPassword from './resetPassword'
import apiCalls from './apiCalls'
import variables from './variables'
import schedules from './schedules'
import tags from './tags'
import themes from './themes'
import nlu from './nlu'
import footer from './footer'
import chats from './chats'
import forking from './forking'
import conditions from './conditions'
import changePassword from './changePassword'
import chatTransitionSettings from './chatTransitonSettings'
import videoCall from './videoCall'
import clustering from './clustering'
import filters from './filters'
import setPassword from './setPassword'
import accountSetup from './accountSetup'
import audit from './audit'
import companies from './companies'
import security from './security'
import staffRoles from './staffRoles'
import queues from './queues'
import answersList from './answersList'
import answersWorkspace from './answersWorkspace'
import feedback from './feedback'
import chartBuilder from './chartBuilder'
import conditionsCatalog from './conditionsCatalog'
import highlightable from './highlightable'
import proactiveRule from './proactiveRule'
import answerMessage from './answerMessage'
import tagCategory from './tagCategory'
import answerDeleteModal from './answerDeleteModal'
import passwordControls from './passwordControls'
import variableDeleteModal from './variableDeleteModal'
import sso from "./sso";
import agentJoined from './agentJoined'
import konaFontPicker from './konaFontPicker'

export default {
  general,
  login,
  attentionRequests,
  botMaker,
  metrics,
  errors,
  users,
  sidebar,
  resetPassword,
  apiCalls,
  variables,
  schedules,
  tags,
  themes,
  nlu,
  footer,
  chats,
  forking,
  conditions,
  changePassword,
  chatTransitionSettings,
  videoCall,
  clustering,
  filters,
  setPassword,
  accountSetup,
  audit,
  companies,
  security,
  staffRoles,
  queues,
  answersList,
  answersWorkspace,
  feedback,
  conditionsCatalog,
  chartBuilder,
  highlightable,
  proactiveRule,
  answerMessage,
  tagCategory,
  answerDeleteModal,
  passwordControls,
  variableDeleteModal,
  sso,
  agentJoined,
  konaFontPicker
}
