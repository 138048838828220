const state = {
  enabledApps: [],
  apiCalls: [],
  entities: [],
  variables: [],
  schedules: [],
  tags:[],
  bot: undefined,
  botId: undefined,
  // For storing all intentions
  intentions: [],  
  botVersions: [],
  activeVersion: undefined,
  fork: null,
}

export default state
