import UserFeedback from '../../services/userFeedback.service'

export default {
  async GET_FEEDBACKS({ commit }, queryParams) {
    try {
      const response = await UserFeedback.getAll(queryParams)
      commit('SET_FEEDBACKS', response.data.result);
      commit('SET_FEEDBACKS_LENGTH', response.data.length);
      return response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
};