/* eslint-disable no-unreachable */
import Request from './request'
import state from '../store/state'

const PATH = {
  CHAT: '/people',
  CHATS: '/people/all',
  PENDING: '/people/pending',
  EXPORT_ALL: '/people/export-all'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getChats = (queryParams) => {
  const token = _getToken()

  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
  }

  return Request.get(
    // `${state.config.konecta}${PATH.CHATS}${/^\//.test(queryParams) ? queryParams : '?' + queryParams}${queryParams}`,
    `${state.config.konecta}${PATH.CHATS}${
      /^\//.test(queryParams) ? queryParams : '?' + queryParams
    }`,
    config
  )
}

const getPendingChats = (queryParams) => {
  const token = _getToken()

  const config = {
    headers: {
      authorization: `Bearer ${token}`
    },
  }

  return Request.get(
    `${state.config.konecta}${PATH.PENDING}${
      /^\//.test(queryParams) ? queryParams : '?' + queryParams
    }`,
    config
  )
}

const getChat = (chat, service) => {
  const token = _getToken()

  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  const queryParams = `where[chat]=${chat}&where[service]=${service}`

  return Request.get(
    `${state.config.konecta}${PATH.CHAT}?${queryParams}`,
    config
  )
}

const exportAll = queryParams => {
  const token = _getToken()

  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }

  return Request.get(
    `${state.config.konecta}${PATH.EXPORT_ALL}?${queryParams}`,
    config
  )
}

export default {
  getChats,
  getChat,
  getPendingChats,
  exportAll
}
