export default {
  multiselector: {
    placeholder: {
      es: 'Elija la categoría de la etiqueta',
      en: 'Choose the tag category',
      pt: 'Escolha a categoria da etiqueta',
    }
  },
  name: {
    es: 'Nombre',
    en: 'Name',
    pt: 'Nome',
  },
  key: {
      es: 'Llave',
      en: 'Key',
      pt: 'Chave'
    },
  table: {
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    key: {
      es: 'Llave',
      en: 'Key',
      pt: 'Chave'
    },
  },
  add: {
    popUpTitle: {
      es: 'Añadir categoría de etiqueta',
      en: 'Add tag category',
      pt: 'Adicionar categoria da etiqueta',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    labelEmpty:{
      es: 'vacío',
      en: 'empty',
      pt: 'vazio',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La categoría de etiquetas se ha creado correctamente.',
        en: 'The tag category was created successfully.',
        pt: 'A categoria tag foi criada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  remove: {
    dialog: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Está a punto de borrar una categoría de etiquetas, esta acción es irreversible. ¿Está usted seguro? ',
        en: 'You are about to erase a tag category, this action is irreversible. Are you sure?',
        pt: 'Você está prestes a apagar uma categoria de tags, esta ação é irreversível. Você tem certeza?',
      },
    },
    dialogAccept: {
      title: {
        es: 'Advertencia!',
        en: 'Warning!',
        pt: 'Aviso!',
      },
      text: {
        es: 'Esta categoría de etiqueta está siendo utilizada, no es posible eliminarla.',
        en: 'This tag category is being used, it is not possible to delete it.',
        pt: 'Esta categoria de etiqueta está sendo usada, não é possível apagá-la.',
      },
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La categoría de la etiqueta se ha eliminado correctamente.',
        en: 'The tag category was successfully removed.',
        pt: 'A categoria da etiqueta foi removida com sucesso.',
      },
    },
  },
  edit: {
    popUpTitle: {
      es: 'Editar categoría de etiqueta',
      en: 'Edit tag category',
      pt: 'Categoria da etiqueta de edição',
    },
    name: {
      es: 'Nombre',
      en: 'Name',
      pt: 'Nome',
    },
    addValue: {
      es: '+ Agregar valor',
      en: '+ Add value',
      pt: '+ Adicione valor'
    },
    value: {
      es: 'Valor',
      en: 'Value',
      pt: 'Valor',
    },
    cancelText: {
      es: 'Cancelar',
      en: 'Cancel',
      pt: 'Cancelar',
    },
    saveText: {
      es: 'Guardar',
      en: 'Save',
      pt: 'Salvar',
    },
    success: {
      title: {
        es: 'Éxito',
        en: 'Success',
        pt: 'Sucesso',
      },
      text: {
        es: 'La categoría de la etiqueta se ha actualizado correctamente.',
        en: 'The tag category was updated successfully.',
        pt: 'A categoria da etiqueta foi atualizada com sucesso.',
      },
    },
    error: {
      title: {
        es: 'Oops!',
        en: 'Oops!',
        pt: 'Oops!',
      },
      text: {
        es: 'Parece que ocurrió un error.',
        en: 'Looks like something went wrong.',
        pt: 'Parece que algo deu errado.',
      },
    },
  },
  readOnly: {
    popUpTitle: {
      es: 'Ver Categoría de Etiqueta (solo lectura)',
      en: 'View Tag Category (read-only)',
      pt: 'Ver Categoria de Tag (somente leitura)',
    }
  },
}