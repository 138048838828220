import ConditionCatalog from '../../services/conditionCatalog.service'

export default {
  async GET_CONDITIONS_CAT({ commit }, queryParams) {
    try {
      const response = await ConditionCatalog.getAll(queryParams)
      commit('SET_CONDITIONS_CAT', response.data.result);
      commit('SET_CONDITIONS_CAT_LENGTH', response.data.length);
      return response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
};