import Request from './request'
import state from '../store/state'

const PATH = {
  PROACTIVE_RULE: '/proactive-rules'
}

const _getToken = () => {
  const session = localStorage.getItem('session')
  if (session) {
    return JSON.parse(session).token
  }
}

const getAll = async (queryParams) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`,

    },
    params: {
      ...queryParams,
    }
  }
  return Request.get(`${state.config.konecta}${PATH.PROACTIVE_RULE}`, config)
}

const getAllWhit = (versionId = null) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const query=(versionId)?`?where[version]=${versionId}`:''
  return Request.get(`${state.config.konecta}${PATH.PROACTIVE_RULE}${query}`, config)
}


const getNameVersion= (name,versionId ) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  const query=(versionId)?`?where[version]=${versionId}&where[name]=${name}`:''
  return Request.get(`${state.config.konecta}${PATH.PROACTIVE_RULE}${query}`, config)
}

const getId = id => {
    const token = _getToken()
    const config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }

    return Request.get(`${state.config.konecta}${PATH.PROACTIVE_RULE}/${id}`, config)
}

const create = payload => {
  const token = _getToken()
  const config = {
      headers: {
          authorization: `Bearer ${token}`
      }
  }
  return Request.post(`${state.config.konecta}${PATH.PROACTIVE_RULE}`, payload, config)
}

const update = (id, payload) => {
  const token = _getToken()
  const config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return Request.put(`${state.config.konecta}${PATH.PROACTIVE_RULE}/${id}`, payload, config)
}

const deleteId = id => {
    const token = _getToken()
    const config = {
      headers: {
        authorization: `Bearer ${token}`
      }
    }
  
    return Request.delete(
      `${state.config.konecta}${PATH.PROACTIVE_RULE}/${id}`, config
    )
  }

export default {
  getNameVersion,
  getAllWhit,
  create,
  update,
  deleteId,
  getAll,
  getId,
}
