
const ROLE_PRIORITY = {
  'company_sys_admin': 1,
  'company_superadmin': 2,
  'company_editor': 3,
  'company_supervisor': 4,
  'company_roles_manager': 5,
  'company_agent': 6,
  'company_guest': 7
};

const ROLE_MAPPING = {
  '5cd9a8f9f99a38006e7b8b1c': 'company_superadmin',
  '5ca511218f8f640063c52468': 'company_editor',
  '5cd09eb2e9d215006fff774b': 'company_supervisor',
  '5cd08f88e9d215006fff7716': 'company_agent',
  '5e98728cce9d06df60693b6a': 'company_roles_manager',
  '6077203875f88029147af507': 'company_sys_admin',
  '662ff10cd2fcf35131869e88': 'company_guest',
};

const getters = {
  userHasWritePermissionRole: (state, getters, rootState) => (route) => {
    const userRoles = rootState.session.user.roles.ids.map(roleId => ROLE_MAPPING[roleId]);
    const highestRole = userRoles.reduce((highest, role) => {
      return ROLE_PRIORITY[role] < ROLE_PRIORITY[highest] ? role : highest;
    }, userRoles[0]);
    if (highestRole === 'company_guest') {
      return true;
    }
    const routeParts = route.split('/');
    const routePrefix = `/${routeParts[1]}`;
    if (routePrefix && state.screenPermissions[routePrefix]) {
      return !state.screenPermissions[routePrefix].includes(highestRole);
    }

    return true;
  }
};

export default getters;
