export default {
  joinMessage: {
    es: 'está aquí para ayudarte',
    en: 'is here to help you',
    pt: 'está aqui para te ajudar'
  },
  lefMessage: {
    es: 'dejó el chat',
    en: 'left the chat',
    pt: 'saiu do chat'
  }
};
