import { channelsFactory } from './factories';
import IntegrationsService from '@/services/integrations.service'
import ChannelService from '@/services/channels.service'

/** @type {import('vuex').Module} */
export default {
  namespaced: true,
  state: {
    integrations: [],
    botChannels: channelsFactory(),
  },
  getters: {
    integrations: state => {
      return state.integrations
    },
    botChannels: state => {
      return state.botChannels
    },
  },
  mutations: {
    CREATE_INTEGRATION(state, integration) {
      state.integrations = [integration].concat(state.integrations)
    },
    /**
     * Because "Konecta Web" channel is not included on the Channel List
     * from the API, we need to manually load the metadata for this channel
     * 
     * @param {object[]} integrations - Existing integrations on the Bot Service
     */
    LOAD_WEB_CHANNEL_METADATA(state, integrations) {
      const webChannel = integrations.find(i => i.type === 'web') 
      const index = state.botChannels.findIndex(c => c.type === 'web')
      
      if (index > -1) {
        state.botChannels[index].active = webChannel.active
        state.botChannels[index]._id = webChannel._id
      }
    },
    RESET_BOT_CHANNELS(state) {
      state.botChannels = channelsFactory()
    },
    RESET_BOT_INTEGRATIONS(state) {
      state.integrations = []
    },
  },
  actions: {
    GET_CHANNELS({ state }){
      // "more than 1" because Web Channel always exists
      if (state.botChannels.length > 1) {
        return state.botChannels
      }
  
      return ChannelService.getChannels().then(response => {
        const botChannels = [
          ...channelsFactory(),
          ...response.data.map(channel => ({
            id: channel._id,
            type: channel.type,
            name: channel.name,
            active: false,
            enabled: false
          })
        )];
        state.botChannels = botChannels;
      })
      .catch(error => error)
    },
    GET_INTEGRATIONS({ state }, params) {
      return IntegrationsService.getIntegrations(params || {})
        .then(response => { state.integrations = response.data })
        .catch(error => error)
    },
    CREATE_INTEGRATION({ commit }, params) {
      return IntegrationsService.createIntegration(params)
        .then(response => {
          commit('CREATE_INTEGRATION', response.data)
        })
    },
  }
}
