import ContactService from '@/services/contact.service'

const actions = {
  getChats(context, queryParams) {
    return ContactService.getChats(queryParams).catch(error => {
      throw error
    })
  },
  getPendingChats(context, queryParams) {
    return ContactService.getPendingChats(queryParams).catch(error => {
      throw error
    })
  },
  exportAll(context, queryParams) {
    return ContactService.exportAll(queryParams).catch(error => {
      throw error
    })
  }
}

export default actions
