import AnswerComponentService from '../../services/answerComponent.service.js'

export default {
  async GET_ANSWERS_COMPONENT({ commit }, companyId) {
    try {
      const response = await AnswerComponentService.getAll(companyId)
      commit('SET_ANSWERS_COMPONENT', response.data);
      return response.data;
    } catch (error) {
      console.error(error)
      return await Promise.reject(error)
    }
  },
};