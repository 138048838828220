const getters = {
  queues: state => {
    return state.queues
  },
  selectedQueue: state => {
    return state.selectedQueue
  },
}

export default getters
