import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

import state from './state'
import mutations from './mutations'
import actions from './actions'

import moduleChat from './chat/moduleChat'
import moduleBotMaker from './botMaker/moduleBotMaker'
import moduleBots from './bots/moduleBots'
import moduleCredentials from './credentials/moduleCredentials'
import integrations from './integrations'
import ipRanges from './ipRanges'
import clusters from './clusters'
import queues from './queues'
import contact from './contact'
import companies from './companies'
import dialogs from './dialogs'
import answerComponent from './answerComponent'
import faunaWidget from './faunaWidget'
import feedback from './feedback'
import chartBuilder from './chartBuilder'
import chartBuilderGrouped from './chartBuilderGrouped'
import answerHistory from './answerHistory'
import conditionCatalog from './conditionCatalog'
import proactiveRule from './proactiveRule'
import variables from './variables'
import tags from './tags'
import templateBubbles from './templateBubbles'
import tagCategories from './tagCategories'
import permissions from './permissions'
import allChats from './allChats'

export default new Vuex.Store({
  mutations,
  state,
  actions,
  modules: {
    allChats,
    chat: moduleChat,
    botMaker: moduleBotMaker,
    bots: moduleBots,
    credentials: moduleCredentials,
    integrations,
    ipRanges,
    clusters,
    contact,
    companies,
    queues,
    dialogs,
    answerComponent,
    faunaWidget,
    feedback,
    answerHistory,
    chartBuilder,
    conditionCatalog,
    proactiveRule,
    variables,
    tags,
    templateBubbles,
    tagCategories,
    chartBuilderGrouped,
    permissions
  },
  strict: false
})
